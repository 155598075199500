import { Dialog, Transition } from '@headlessui/react';
import * as React from 'react';
import { DialogContentContainer } from './DialogContentContainer';
import { ConfirmationModalContent } from './ConfirmationModalContent';

interface Props {
  isOpen: boolean;
  onConfirm: (answer: boolean) => void;
  title: string;
  okButtonText?: string;
  cancelButtonText?: string;
  destructive?: boolean;
}

export const ConfirmationDialog: React.FC<Props> = ({
  isOpen,
  onConfirm,
  children,
  title,
  okButtonText = 'OK',
  cancelButtonText = 'Cancel',
  destructive = false,
}) => {
  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        static
        open={isOpen}
        onClose={() => onConfirm(false)}
        as="div"
        id="modal"
        className="fixed inset-0 z-20 overflow-y-auto flex justify-center items-center"
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-60"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-grey-700 bg-opacity-60 backdrop-filter backdrop-blur-sm" />
        </Transition.Child>

        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="max-w-sm w-full px-4">
            <DialogContentContainer>
              <ConfirmationModalContent
                title={title}
                onOk={() => onConfirm(true)}
                onCancel={() => onConfirm(false)}
                destructive={destructive}
                okButtonText={okButtonText}
                cancelButtonText={cancelButtonText}
              >
                {children}
              </ConfirmationModalContent>
            </DialogContentContainer>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};
