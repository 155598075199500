import * as React from 'react';

interface Props {
  loading: boolean;
}

export const LoadingSpinner: React.FC<{
  className?: string;
}> = ({ className = 'border-white' }) => {
  return (
    <div
      aria-busy="true"
      aria-label="Loading"
      data-testid="loader"
      className={`border-4 w-10 h-10 rounded-full m-4 animate-spin ${className}`}
      style={{
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
      }}
    />
  );
};

export const Loader: React.FC = () => {
  return (
    <div className="mt-24 flex justify-center items-center">
      <LoadingSpinner />
    </div>
  );
};

export const ContentLoader = () => (
  <div className="py-10 flex justify-center items-center">
    <LoadingSpinner className="border-primary" />
  </div>
);

export const LoadingContainer: React.FC<Props> = ({ loading, children }) => {
  if (loading) {
    return (
      <div className="py-12">
        <LoadingSpinner className="border-primary-500 mx-auto" />
      </div>
    );
  }

  return <>{children}</>;
};
