import { pipe, identity } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as E from 'fp-ts/Either';
import { formatValidationErrors } from 'io-ts-reporters';

const envConfig = {
  apiHost: import.meta.env.VITE_API_HOST,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,
  segmentKey: import.meta.env.VITE_SEGMENT_KEY,
  environment: import.meta.env.VITE_BUILD_ENV || 'dev',
  version: import.meta.env.VITE_VERSION,
  mapboxToken: import.meta.env.VITE_MAPBOX_TOKEN || '',
  stripePublishableKey:
    import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || 'pk_test',
  analyticsDriver: import.meta.env.VITE_ANALYTICS_DRIVER || 'console',
  features: {
    matchImages: true,
  },
  // TODO: Update to env vars
  imgkit: {
    publicKey: import.meta.env.VITE_IMGKIT_PUBLIC_KEY,
    urlEndpoint: import.meta.env.VITE_IMGKIT_URL,
    authenticationEndpoint: '/imgkit_signature',
  },
  rootCookieDomain:
    import.meta.env.VITE_ROOT_COOKIE_DOMAIN || window.location.hostname,
  vapidPublicKey: import.meta.env.VITE_VAPID_PUBLIC_KEY,
  turnstileSiteKey:
    import.meta.env.VITE_TURNSTILE_SITE_KEY || '1x00000000000000000000AA',
  posthogToken: import.meta.env.VITE_POSTHOG_TOKEN || '',
  posthogApiHost:
    import.meta.env.VITE_POSTHOG_API_HOST || 'https://eu.i.posthog.com',
};

const Config = t.type({
  // the root host (including port if non-standard) for the api)
  apiHost: t.string,
  sentryDsn: t.union([t.string, t.undefined]),
  segmentKey: t.union([t.string, t.undefined]),
  environment: t.string,
  version: t.union([t.string, t.undefined]),
  mapboxToken: t.string,
  stripePublishableKey: t.string,
  analyticsDriver: t.union([
    t.literal('null'),
    t.literal('segment'),
    t.literal('console'),
    t.literal('posthog'),
  ]),
  features: t.type({
    matchImages: t.boolean,
  }),
  // TODO: Update to env vars
  imgkit: t.type({
    publicKey: t.string,
    urlEndpoint: t.string,
  }),
  rootCookieDomain: t.string,
  // this should match the vapid public key for the issuing server
  vapidPublicKey: t.string,
  turnstileSiteKey: t.string,
  posthogToken: t.string,
  posthogApiHost: t.string,
});

export const config = pipe(
  envConfig,
  Config.decode,
  E.mapLeft(formatValidationErrors),
  E.fold((e) => {
    throw new Error(`Error parsing config: ${e}`);
  }, identity),
);
