import * as React from 'react';

import { Failure } from './pages/Failure';
import { Analytics } from './services/analytics';

interface Props {
  children: React.ReactNode;
  logError: (error: any) => void;
  reloadPage: () => void;
  analytics: Analytics;
}

interface State {
  hasError: boolean;
  isAboutToReload: boolean;
}

const isChunkLoadingError = (message: string) => {
  const chunkLoadingMessages = [
    /importing a module script failed/i,
    /failed to fetch dynamically imported module/i,
    /error loading dynamically imported module/i,
    /Loading chunk \d+ failed/i,
    /Loading CSS chunk \d+ failed/i,
  ];

  return chunkLoadingMessages.some((pattern) => pattern.test(message));
};

export class ErrorBoundary extends React.Component<Props, State> {
  readonly state = { hasError: false, isAboutToReload: false };

  componentDidCatch(error: Error) {
    if (isChunkLoadingError(error.message)) {
      this.props.analytics.track('PageReload Forced', {
        reason: error.message,
      });
      this.setState({ isAboutToReload: true });
      this.props.reloadPage();
      return;
    }
    this.props.logError(error);
    this.setState({ hasError: true });
  }

  render() {
    // we render nothing if we're about to reload to avoid the flash of the fail page
    if (this.state.isAboutToReload) {
      return null;
    }
    if (this.state.hasError) {
      return <Failure onNavigate={() => this.setState({ hasError: false })} />;
    }

    return this.props.children;
  }
}
