import { pipe, tap } from 'wonka';
import { Exchange } from 'urql';
import { GraphQLError } from 'graphql';

const isNotUnauthorizedError = (error: GraphQLError) => {
  return error.message.toLowerCase() !== 'unauthorized';
};

/**
 * Check for any errors returned from in the graphql response
 * and report them using the `reportError` function
 *
 * @param reportError
 */
export const errorExchange =
  (reportError: (error: GraphQLError) => void): Exchange =>
  ({ forward }) =>
  (ops$) => {
    return pipe(
      forward(ops$),
      tap(({ error }) => {
        if (error?.graphQLErrors) {
          error.graphQLErrors
            .filter(isNotUnauthorizedError)
            .forEach(reportError);
        }
      }),
    );
  };
