import * as React from 'react';

type Props = React.ComponentProps<'div'>;

export const Container: React.FC<Props> = ({ className = '', ...props }) => {
  return (
    <div
      {...props}
      className={`w-full max-w-5xl mx-auto px-6 md:px-6 ${className}`}
    />
  );
};

/**
 * On smaller screens this container will stretch to touch the edges so there's
 * no gutter between the content and the edge of the screen.
 */
export const EdgeContainer: React.FC<Props> = ({
  className = '',
  ...props
}) => {
  return <div {...props} className={`-mx-6 sm:mx-auto ${className}`} />;
};
