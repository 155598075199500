export const paths = {
  home: () => '/',
  signIn: () => '/log-in',
  register: () => '/sign-up',
  confirmEmail: () => '/email/confirm',
  onboarding: {
    _root: '/get-started',
    home: () => '/get-started',
    createTeam: () => '/get-started/create-team',
    joinTeam: () => '/get-started/join-team',
  },
  account: {
    home: () => '/account',
    password: () => '/account/password',
    notifications: () => '/account/notifications',
    profile: () => '/account/profile',
    mfa: () => '/account/mfa',
  },
  activities: {
    home: (activityId = ':id') => `/activities/${activityId}`,
  },
  password: {
    forgot: () => '/password/forgot',
    reset: () => '/password/reset',
  },
  referrals: {
    shareLink: () => '/refer',
    redeem: (code = ':code') => `/refer/${code}`,
  },
  devices: {
    _root: () => '/devices',
    home: () => '/devices',
    authSuccess: () => '/devices/auth/success',
  },
  clubs: {
    home: (clubId = ':id') => `/clubs/${clubId}`,
    upcomingMatches: (clubId = ':id') => `/clubs/${clubId}/upcoming-matches`,
    previousMatches: (clubId = ':id') => `/clubs/${clubId}/previous-matches`,
    requests: (clubId = ':id') => `/clubs/${clubId}/requests`,
    availabilities: (clubId = ':id') => `/clubs/${clubId}/availabilities`,
  },
  playerSettings: {
    home: () => `/settings/player`,
    leaveTeam: () => `/settings/player/leave-team`,
  },
  teamSettings: {
    home: () => `/settings/team`,
    details: () => `/settings/team/details`,
    name: () => `/settings/team/name`,
    seasons: {
      home: () => `/settings/team/seasons`,
      new: () => `/settings/team/seasons/new`,
      edit: (seasonId = ':seasonId') =>
        `/settings/team/seasons/${seasonId}/edit`,
    },
    players: {
      home: () => `/settings/team/players`,
      squad: () => `/settings/team/squad`,
      edit: (playerId = ':playerId') =>
        `/settings/team/players/${playerId}/edit`,
      captainPlayer: () => `/settings/team/captain`,
      coCaptains: () => `/settings/team/co-captains`,
      transfer: () => `/settings/team/transfer`,
      merge: () => `/settings/team/players/merge`,
    },
    matches: {
      home: () => '/settings/team/matches',
      voteTypes: () => `/settings/team/voteTypes`,
      payments: () => `/settings/team/payments`,
    },
    kit: {
      home: () => `/settings/team/kit`,
      edit: (kitId = ':kitId') => `/settings/team/kit/${kitId}/edit`,
    },
    location: () => `/settings/team/location`,
    description: () => `/settings/team/description`,
    league: () => `/settings/team/league`,
    club: {
      clubIntro: () => '/settings/team/club',
      joinClub: () => '/settings/team/club/search',
      createClub: () => '/settings/team/club/new',
    },
    joinRequests: {
      home: () => `/settings/team/join-requests`,
      _root: '/settings/team/join-requests/:requestId',
      review: (requestId = ':requestId') =>
        `/settings/team/join-requests/${requestId}/review`,
      assign: (requestId = ':requestId') =>
        `/settings/team/join-requests/${requestId}/assign`,
      create: (requestId = ':requestId') =>
        `/settings/team/join-requests/${requestId}/create`,
    },
  },
  team: {
    _root: '/team/:id([0-9]+)',
    new: () => '/team/new',
    club: () => `/team/club`,
    share: (teamId = ':id') => `/team/${teamId}/share`,
    leaderboards: {
      home: (teamId = ':id') => `/team/${teamId}/leaderboards`,
      votes: (teamId = ':id', type = ':type(dotd|motm)') =>
        `/team/${teamId}/leaderboards/votes/${type}`,
      matchesPlayed: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/matches-played`,
      matchesWon: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/matches-won`,
      matchesDrawn: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/matches-drawn`,
      matchesLost: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/matches-lost`,
      assists: (teamId = ':id') => `/team/${teamId}/leaderboards/assists`,
      goals: (teamId = ':id') => `/team/${teamId}/leaderboards/goals`,
      goalsFor: (teamId = ':id') => `/team/${teamId}/leaderboards/goals-for`,
      goalsAgainst: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/goals-against`,
      averageGoalsFor: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/average-goals-for`,
      averageGoalsAgainst: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/average-goals-against`,
      goalsPerGame: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/goals-per-game`,
      cleanSheets: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/clean-sheets`,
      cleanSheetsRate: (teamId = ':id') =>
        `/team/${teamId}/leaderboards/clean-sheets-rate`,
      winRate: (teamId = ':id') => `/team/${teamId}/leaderboards/win-rate`,
    },
    matches: {
      past: (teamId = ':id') => `/team/${teamId}/matches/past`,
      future: (teamId = ':id') => `/team/${teamId}/matches/upcoming`,
    },
  },

  match: {
    home: (matchId = ':id') => `/m/${matchId}`,
    availability: (matchId = ':id') => `/m/${matchId}/availability`,
    matchReport: (matchId = ':id') => `/m/${matchId}/match-report`,
    votes: {
      vote: (matchId = ':id') => `/m/${matchId}/vote`,
      create: (matchId = ':id', voteTypeId = ':voteTypeId') =>
        `/m/${matchId}/votes/create/${voteTypeId}`,
      read: (matchId = ':id') => `/m/${matchId}/votes/read`,
      summary: (matchId = ':id') => `/m/${matchId}/votes/summary`,
    },
    settings: {
      home: (matchId = ':id') => `/m/${matchId}/edit`,
      details: (matchId = ':id') => `/m/${matchId}/edit/details`,
      location: (matchId = ':id') => `/m/${matchId}/edit/location`,
      squad: (matchId = ':id') => `/m/${matchId}/edit/pick-squad`,
      kit: (matchId = ':id') => `/m/${matchId}/edit/kit`,
      score: (matchId = ':id') => `/m/${matchId}/edit/stats/score`,
      scorers: (matchId = ':id') => `/m/${matchId}/edit/stats/scorers`,
      cards: (matchId = ':id') => `/m/${matchId}/edit/stats/cards`,
      assists: (matchId = ':id') => `/m/${matchId}/edit/stats/assists`,
      matchReport: (matchId = ':id') => `/m/${matchId}/edit/match-report`,
    },
  },
  player: {
    home: (playerId = ':id([0-9]+)') => `/p/${playerId}`,
    votes: (playerId = ':id([0-9]+)') => `/p/${playerId}/votes`,
  },
  stats: {
    home: () => `/stats`,
  },
  newMatch: () => `/match/new`,
  subscriptions: {
    /** If linking here use the `InternalLink` to ensure the back functionality works */
    home: () => `/subscribe`,
    success: () => `/subscribe/success`,
  },
  joinTeam: {
    home: () => '/join-team',
    team: (teamId = ':id([0-9]+)') => `/join-team/${teamId}`,
  },
};
