import * as React from 'react';
import * as O from 'fp-ts/Option';
import Cookies from 'js-cookie';
import { pipe } from 'fp-ts/lib/function';
import { PillButton } from '../components/PillButton';
import { config } from '../config';
import { ExternalTextLink } from '../components/ClickableText';

interface Props {}

const COOKIE_CONSENT_COOKIE = 'mh_cc';

const CookieConsentContext = React.createContext<O.Option<boolean>>(O.none);

const CookieConsent: React.FC<{
  onConsent: () => void;
  onDeny: () => void;
}> = ({ onConsent, onDeny }) => {
  return (
    <div className="fixed z-30 bottom-0 w-full sm:px-6">
      <div className="max-w-4xl mx-auto bg-white p-6 pb-20 sm:pb-6 border-t sm:border-l sm:border-r border-grey-300 sm:rounded-t-lg space-y-4 shadow-lg">
        <h2 className="text-xl font-semibold">Can we use optional cookies?</h2>
        <p>
          These cookies help us keep our website safe and give you a better
          experience. We won't turn them on unless you accept. Want to know
          more? Here's our{' '}
          <ExternalTextLink
            href="https://matchhaus.io/cookies"
            target="_blank"
            rel="noreferrer"
          >
            cookie notice
          </ExternalTextLink>
          .
        </p>
        <div className="flex space-x-4">
          <PillButton onClick={onConsent} kind="primary">
            Accept
          </PillButton>
          <PillButton onClick={onDeny}>Decline</PillButton>
        </div>
      </div>
    </div>
  );
};

export const CookieConsentProvider: React.FC<Props> = ({ children }) => {
  const [consented, setConsented] = React.useState(() =>
    pipe(
      Cookies.get(COOKIE_CONSENT_COOKIE),
      O.fromNullable,
      O.map((v) => v === '1'),
    ),
  );

  const cookieProps: Cookies.CookieAttributes = {
    sameSite: 'Strict',
    expires: 365,
    domain: config.rootCookieDomain,
  };

  const onConsent = () => {
    Cookies.set(COOKIE_CONSENT_COOKIE, '1', cookieProps);
    setConsented(O.some(true));
  };
  const onDeny = () => {
    Cookies.set(COOKIE_CONSENT_COOKIE, '0', cookieProps);
    setConsented(O.some(false));
  };

  return (
    <CookieConsentContext.Provider value={consented}>
      {O.isNone(consented) ? (
        <CookieConsent onConsent={onConsent} onDeny={onDeny} />
      ) : null}
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  const value = React.useContext(CookieConsentContext);

  return value;
};
