import * as React from 'react';
import { ConfirmationDialog } from '../components/ConfirmationDialog';

const ConfirmContext = React.createContext<
  ((props: ConfirmProps) => Promise<boolean>) | undefined
>(undefined);

type ConfirmProps = Pick<
  React.ComponentProps<typeof ConfirmationDialog>,
  'title' | 'children' | 'okButtonText' | 'cancelButtonText' | 'destructive'
>;

export const ConfirmProvider: React.FC = ({ children }) => {
  const [respond, setRespond] = React.useState<(answer: boolean) => void>(
    () => () => null,
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const [confirmProps, setConfirmProps] = React.useState<ConfirmProps | null>(
    null,
  );

  const confirm = (props: ConfirmProps) => {
    setConfirmProps(props);
    setIsOpen(true);
    return new Promise<boolean>((res) => {
      setRespond(() => (answer: boolean) => {
        setIsOpen(false);
        res(answer);
      });
    });
  };

  return (
    <ConfirmContext.Provider value={confirm}>
      <ConfirmationDialog
        isOpen={isOpen}
        onConfirm={respond}
        title={confirmProps?.title || ''}
        okButtonText={confirmProps?.okButtonText}
        cancelButtonText={confirmProps?.cancelButtonText}
        destructive={confirmProps?.destructive}
      >
        {confirmProps?.children}
      </ConfirmationDialog>

      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const confirm = React.useContext(ConfirmContext);

  if (!confirm) {
    throw new Error(
      'useConfirm must be used inside a Confirm Context Provider',
    );
  }

  return confirm;
};
