import * as React from 'react';
import { useHistory } from 'react-router';
import { useAuth } from './contexts/AuthContext';

import { useAnalytics } from './contexts/AnalyticsContext';
import { Loader } from './components/LoadingContainer';
import { ConfirmProvider } from './contexts/ConfirmContext';
import { getPWADisplayMode } from './utils/getPwaDisplayMode';

const UnauthenticatedApp = React.lazy(() =>
  import('./UnauthenticatedApp').then(({ UnauthenticatedApp }) => ({
    default: UnauthenticatedApp,
  })),
);
const AuthenticatedApp = React.lazy(() =>
  import('./AuthenticatedApp').then(({ AuthenticatedApp }) => ({
    default: AuthenticatedApp,
  })),
);

interface Props {
  identifyUser: <U extends object>(user: Partial<U>) => void;
}

export const App: React.FC<Props> = ({ identifyUser }) => {
  const { user } = useAuth();
  const analytics = useAnalytics();
  const history = useHistory();

  // Scroll to the top of page when the route changes
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });

    return () => unlisten();
  }, []);

  // Track page views
  React.useEffect(() => {
    analytics.page(history.location.pathname);
  }, [history.location.pathname]);

  // Identify the user if they are logged in
  React.useEffect(() => {
    if (user) {
      identifyUser(user);
      analytics.identify(user.id, { displayMode: getPWADisplayMode() });
    }
  }, [user?.id]);

  return (
    <ConfirmProvider>
      <React.Suspense fallback={<Loader />}>
        {user ? <AuthenticatedApp user={user} /> : <UnauthenticatedApp />}
      </React.Suspense>
    </ConfirmProvider>
  );
};
