import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time in the UTC
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string, including UTC timezone ("Z"). The parsed date and time string will
   * be converted to UTC if there is an offset.
   */
  DateTime: string;
  /** The UUID scalar type represents a version 4 (random) UUID. Any binary not conforming to this format will be flagged. */
  UUID: string;
  /** Represents an IANA time zone. */
  TimeZone: string;
};

export type ApproveTeamRequestResponse = {
  entity?: Maybe<Player>;
  validation?: Maybe<Array<Validation>>;
};

export type AssignPlayerToUserInput = {
  playerId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Availability = {
  id: Scalars['ID'];
  match: Match;
  player: Player;
  status: AvailabilityStatus;
};

/** The status of an availability */
export type AvailabilityStatus = 
  | 'AVAILABLE'
  | 'MAYBE'
  | 'UNAVAILABLE'
  | 'UNDECLARED';

export type CardRecipient = {
  /** The type of card that was shown. E.g. yellow, red etc */
  cardType: Scalars['String'];
  id: Scalars['ID'];
  match: Match;
  player: Player;
};

export type CheckoutSession = {
  id: Scalars['String'];
};

export type Club = {
  clubRequests?: Maybe<Array<ClubRequest>>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  matches?: Maybe<MatchConnection>;
  name: Scalars['String'];
  teams: Array<Team>;
};


export type ClubMatchesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<SortDirection>;
  filter?: Maybe<ClubMatchFilterType>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
  requiredMatchFields?: Maybe<Array<Scalars['String']>>;
};

export type ClubMatchFilterType = 
  | 'HIGHLIGHTS'
  | 'RESULTS';

export type ClubRequest = {
  club: Club;
  id: Scalars['ID'];
  requestedAt: Scalars['DateTime'];
  team: PublicTeam;
};

export type CreateAccountInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type CreateCardRecipientInput = {
  cardType: Scalars['String'];
  matchId: Scalars['UUID'];
  playerId: Scalars['ID'];
};

export type CreateCardRecipientResponse = {
  entity?: Maybe<CardRecipient>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateCheckoutSessionResponse = {
  error?: Maybe<Error>;
  session?: Maybe<CheckoutSession>;
};

export type CreateClubInput = {
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** The team ID to assign to the club */
  teamId: Scalars['ID'];
};

export type CreateClubRequestInput = {
  clubId: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type CreateClubRequestResponse = {
  entity?: Maybe<ClubRequest>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateClubResponse = {
  entity?: Maybe<Club>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateKitItemInput = {
  name: Scalars['String'];
};

export type CreateKitItemResponse = {
  entity?: Maybe<KitItem>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateMatchEventInput = {
  event: MatchEventType;
  matchId: Scalars['UUID'];
  playerId: Scalars['ID'];
};

export type CreateMatchEventResponse = {
  entity?: Maybe<MatchEvent>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateMatchInput = {
  atHome: Scalars['Boolean'];
  excludedFromStats?: Maybe<Scalars['Boolean']>;
  meetAt?: Maybe<Scalars['DateTime']>;
  opponent: Scalars['String'];
  playedAt: Scalars['DateTime'];
  teamId: Scalars['ID'];
  timeZone: Scalars['TimeZone'];
};

export type CreateMatchResponse = {
  /** The newly created match */
  entity?: Maybe<Match>;
  validation?: Maybe<Array<Validation>>;
};

export type CreatePlayerResponse = {
  entity?: Maybe<Player>;
  validation?: Maybe<Array<Validation>>;
};

export type CreatePortalSessionInput = {
  /** The path to which Stripe should send users to return to the app */
  returnPath: Scalars['String'];
};

export type CreatePortalSessionResponse = {
  url?: Maybe<Scalars['String']>;
};

export type CreatePushSubscriptionInput = {
  endpoint: Scalars['String'];
  keys: Scalars['String'];
};

export type CreateSeasonInput = {
  endedAt: Scalars['DateTime'];
  name: Scalars['String'];
  startedAt: Scalars['DateTime'];
  teamId: Scalars['ID'];
};

export type CreateSeasonResponse = {
  entity?: Maybe<Season>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateSessionInput = {
  /** The path the users will be directed to if they decide to cancel payment. E.g. `/cancel` */
  cancelPath: Scalars['String'];
  /** The plan to which the checkout session should subscribe the user */
  plan: Scalars['String'];
  /** The path to which Stripe should send users when payment is complete. E.g. `/success` */
  successPath: Scalars['String'];
};

export type CreateTeamInput = {
  /** Some blurb about the team */
  description?: Maybe<Scalars['String']>;
  /** A link to a team's league */
  leagueLink?: Maybe<Scalars['String']>;
  /** Where the team is based. E.g. London, UK */
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** The link used to pay the team captain */
  paymentLink?: Maybe<Scalars['String']>;
};

export type CreateTeamRequestResponse = {
  entity?: Maybe<TeamRequest>;
  validation?: Maybe<Array<Validation>>;
};

export type CreateVoteInput = {
  comment?: Maybe<Scalars['String']>;
  /** The ID of the match for which the vote is for */
  matchId: Scalars['UUID'];
  /** The ID of the player being nominated */
  playerId: Scalars['ID'];
  /** The ID of the vote type */
  voteTypeId: Scalars['ID'];
};

export type CreateVoteTypeInput = {
  emoji?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** Control the order in which the vote type is returned in a list */
  position?: Maybe<Scalars['Int']>;
  teamId: Scalars['ID'];
};

export type CreateVoteTypeResponse = {
  entity?: Maybe<VoteType>;
  validation?: Maybe<Array<Validation>>;
};


export type DeleteCardRecipientResponse = {
  error?: Maybe<Scalars['String']>;
  /** The match the card recipient is for */
  match?: Maybe<Match>;
};

export type DeleteImageResponse = {
  validation?: Maybe<Array<Validation>>;
};

export type DeleteKitItemResponse = {
  error?: Maybe<Scalars['String']>;
  /** The team the kit item was for */
  team?: Maybe<Team>;
};

export type DeleteMatchEventResponse = {
  error?: Maybe<Scalars['String']>;
  /** The match the event was for */
  match?: Maybe<Match>;
};

export type DeleteMatchResponse = {
  /** The team the match belonged to */
  entity?: Maybe<Team>;
};

export type DeletePlayerResponse = {
  error?: Maybe<Scalars['String']>;
  /** The team the player belonged to */
  team?: Maybe<Team>;
};

export type DeletePushSubscriptionResponse = {
  user?: Maybe<User>;
};

export type DeleteSeasonResponse = {
  /** The team the season belonged to */
  team?: Maybe<Team>;
};

export type DetachPlayerFromUserInput = {
  playerId: Scalars['ID'];
};

export type EnableTotpInput = {
  code: Scalars['String'];
  secret: Scalars['String'];
};

/** A generic error type to provide information about an operation that failed */
export type Error = {
  message: Scalars['String'];
};

export type Experiment = Node & {
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type FitnessDataAuthUrlInput = {
  /** The path the users will be directed to if they decide to cancel payment. E.g. `/cancel` */
  cancelPath: Scalars['String'];
  /** The path to which Terra should send users when payment is complete. E.g. `/success` */
  successPath: Scalars['String'];
};

export type FitnessDataAuthUrlResponse = {
  sessionUrl: Scalars['String'];
};

export type FitnessDataProvider = 
  | 'FITBIT'
  | 'GARMIN'
  | 'GOOGLE'
  | 'POLAR'
  | 'SUUNTO'
  | 'WAHOO'
  | 'WITHINGS';

export type FitnessDeviceUser = Node & {
  /** The ID of an object */
  id: Scalars['ID'];
  provider: FitnessDataProvider;
};

export type GetUploadUrlInput = {
  contentType: Scalars['String'];
  /** The context for the upload. Used to generate a path. */
  context: UploadContext;
  filename: Scalars['String'];
};

export type GetUploadUrlResponse = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type Image = Node & {
  caption?: Maybe<Scalars['String']>;
  /** The ID of an object */
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type ImageInput = {
  caption?: Maybe<Scalars['String']>;
  path: Scalars['String'];
};

export type InvalidCredentialsLoginError = LoginError & {
  errorType: LoginErrorType;
};

export type KitItem = {
  id: Scalars['UUID'];
  name: Scalars['String'];
  team: Team;
};

export type LeaderboardItem = {
  player: Player;
  rank: Scalars['Int'];
  /** The value of the metric for which the leaderboard is calculated (goal count, win rate etc) */
  score: Scalars['Float'];
};

export type Location = Node & {
  /** The location address */
  address: Scalars['String'];
  /** The ID of an object */
  id: Scalars['ID'];
  /** Location latitude */
  lat: Scalars['Float'];
  /** Location longitude */
  lng: Scalars['Float'];
  timeZone: Scalars['String'];
};

export type LocationInput = {
  /** The location address */
  address: Scalars['String'];
  /** Location latitude */
  lat: Scalars['Float'];
  /** Location longitude */
  lng: Scalars['Float'];
};

export type LoginError = {
  errorType: LoginErrorType;
};

export type LoginErrorType = 
  | 'INVALID_CREDENTIALS'
  | 'MFA_NEEDED';

export type LoginResponse = {
  error?: Maybe<LoginError>;
  session?: Maybe<Session>;
};

export type LogoutResponse = {
  result?: Maybe<Scalars['String']>;
};

export type Match = {
  activities?: Maybe<Array<MatchActivity>>;
  assists: Array<MatchEvent>;
  atHome: Scalars['Boolean'];
  availabilities: Array<Availability>;
  /** The availability of the current user */
  availability?: Maybe<AvailabilityStatus>;
  cardRecipients: Array<CardRecipient>;
  events: Array<MatchEvent>;
  /** Whether the stats from this match (scorers, votes, cards etc) are used when calculating leaderboards */
  excludedFromStats: Scalars['Boolean'];
  goalsAgainst?: Maybe<Scalars['Int']>;
  goalsFor?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The featured image for the match */
  image?: Maybe<Image>;
  /** All images attached to the match */
  images: Array<Image>;
  /** The kit for the match */
  kitItems: Array<KitItem>;
  location?: Maybe<Location>;
  matchActivities?: Maybe<Array<MatchActivity>>;
  matchReport?: Maybe<Scalars['String']>;
  matchReportHtml?: Maybe<Scalars['String']>;
  meetAt?: Maybe<Scalars['DateTime']>;
  opponent: Scalars['String'];
  playedAt: Scalars['DateTime'];
  /** The chosen squad for the match */
  players: Array<Player>;
  scorers: Array<Scorer>;
  /** The team to which the match belongs. */
  team: Team;
  timeZone: Scalars['TimeZone'];
  /** The number of votes that have been cast so far. */
  voteCount: Scalars['Int'];
  /** Collection of vote-related stats for the match. Will be null until the match has closed its voting */
  voteResult?: Maybe<MatchVoteResult>;
  /** The IDs of the vote types that the current user has cast for the match */
  voteTypesCast?: Maybe<Array<Scalars['String']>>;
  /** The time that voting was closed at. If null voting is still open and votes cannot be read. */
  votingClosedAt?: Maybe<Scalars['DateTime']>;
  votingStatus: MatchVoteStatus;
};

export type MatchActivity = Node & {
  /** The ID of an object */
  id: Scalars['ID'];
  match?: Maybe<Match>;
  /** The player for the activity. Will only be set if the activity is linked to a match. */
  player?: Maybe<Player>;
  positions: Array<Position>;
  summaryData: MatchActivityData;
  /** @deprecated Use `positions` */
  timeseriesSamples: Array<Position>;
};

export type MatchActivityData = {
  /** Average heart rate */
  avgHr?: Maybe<Scalars['Int']>;
  /** Average speed in m/s */
  avgSpeed?: Maybe<Scalars['Float']>;
  calories?: Maybe<Scalars['Int']>;
  device?: Maybe<Scalars['String']>;
  /** Distance travelled in meters */
  distance?: Maybe<Scalars['Int']>;
  maxHr?: Maybe<Scalars['Int']>;
  /** Max speed in m/s */
  maxSpeed?: Maybe<Scalars['Float']>;
  steps?: Maybe<Scalars['Int']>;
};

export type MatchConnection = {
  edges: Array<MatchEdge>;
  pageInfo: PageInfo;
};

export type MatchEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Match;
};

export type MatchEvent = {
  event: MatchEventType;
  id: Scalars['ID'];
  match: Match;
  player: Player;
};

export type MatchEventType = 
  | 'ASSIST'
  | 'GOAL_SCORED'
  | 'GREEN_CARD'
  | 'RED_CARD'
  | 'YELLOW_CARD';

export type MatchImage = Node & {
  /** The ID of an object */
  id: Scalars['ID'];
  order: Scalars['Int'];
};

export type MatchVoteResult = {
  /**
   * Counts of the votes received by each player.
   * Only players who have received a vote will be included in the list.
   */
  counts: Array<PlayerVoteCount>;
  /**
   * The votes that have been cast for the match.
   *   Will be `null` if the user is not authorised to read the votes.
   */
  votes?: Maybe<Array<Vote>>;
  /** The winners for each vote type for the match. */
  winners: Array<PlayerVoteCount>;
};


export type MatchVoteResultCountsArgs = {
  voteTypeId?: Maybe<Scalars['ID']>;
};

export type MatchVoteStatus = 
  | 'ACTIVE'
  | 'CLOSED'
  | 'PENDING';

export type MfaError = {
  errorType: Scalars['String'];
  message: Scalars['String'];
};

export type MfaMethod = 
  | 'TOTP';

export type MfaNeededLoginError = LoginError & {
  errorType: LoginErrorType;
  /** A micro-session token to be sent back to the server along with the mfa credential */
  mfaToken: Scalars['String'];
};

export type Node = {
  /** The ID of the object. */
  id: Scalars['ID'];
};

export type Notification = Node & {
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PickPlayersInput = {
  /** The ID of the match for which the players are being picked */
  matchId: Scalars['UUID'];
  /** A list of IDs for the players being picked */
  playerIds: Array<Scalars['ID']>;
};

export type PickPlayersResponse = {
  /** The match that the players has been picked for */
  entity?: Maybe<Match>;
  validation?: Maybe<Array<Validation>>;
};

/** A plan that can be subscribed to */
export type Plan = {
  active: Scalars['Boolean'];
  amount: Scalars['Int'];
  id: Scalars['ID'];
  interval: Scalars['String'];
  keyPoints?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
};

export type Player = {
  events?: Maybe<PlayerEventConnection>;
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  /** Is the current user connected to this player? (i.e. a teammate or team captain) */
  isConnected: Scalars['Boolean'];
  matches?: Maybe<MatchConnection>;
  name: Scalars['String'];
  stats?: Maybe<PlayerStats>;
  team: PublicTeam;
  user?: Maybe<User>;
  votes?: Maybe<VoteConnection>;
};


export type PlayerEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PlayerMatchesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<SortDirection>;
  first?: Maybe<Scalars['Int']>;
  ignoreStatsExcludedMatches?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
};


export type PlayerStatsArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type PlayerVotesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  voteTypeId?: Maybe<Scalars['ID']>;
};

export type PlayerConnection = {
  edges: Array<PlayerEdge>;
  pageInfo: PageInfo;
};

export type PlayerEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Player;
};

export type PlayerEvent = Node & {
  count: Scalars['Int'];
  event: Scalars['String'];
  eventType: PlayerEventType;
  /** The ID of an object */
  id: Scalars['ID'];
  match: Match;
};

export type PlayerEventConnection = {
  edges: Array<PlayerEventEdge>;
  pageInfo: PageInfo;
};

export type PlayerEventEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: PlayerEvent;
};

export type PlayerEventType = 
  | 'MATCH_EVENT'
  | 'VOTE_WIN';

export type PlayerStat = {
  /** How the player ranks for this metric within their team */
  rank: Scalars['Int'];
  /** The value of the metric for the player stat in question (goal count, win rate etc) */
  score: Scalars['Float'];
};

export type PlayerStats = {
  /** The number of assists the player has made */
  assists?: Maybe<PlayerStat>;
  /** The number of goals conceded per game for the matches the player played in */
  averageGoalsAgainst?: Maybe<PlayerStat>;
  /** The number of goals scored per game for the matches the player played in */
  averageGoalsFor?: Maybe<PlayerStat>;
  /** The number of clean sheets the player has achieved */
  cleanSheets?: Maybe<PlayerStat>;
  /** The percentage of that the player played in that were clean sheets */
  cleanSheetsRate?: Maybe<PlayerStat>;
  /** The number of goals conceded in the matches the player played in */
  goalsAgainst?: Maybe<PlayerStat>;
  /** The number of goals scored in the matches the player played in */
  goalsFor?: Maybe<PlayerStat>;
  /** The goals scored per game the for player */
  goalsPerGame?: Maybe<PlayerStat>;
  /** The number of goals the player has scored */
  goalsScored?: Maybe<PlayerStat>;
  /** The number of matches that the player played in that were won */
  matchesDrawn?: Maybe<PlayerStat>;
  /** The number of matches that the player played in that were won */
  matchesLost?: Maybe<PlayerStat>;
  /** The number of matches that the player was in the squad for */
  matchesPlayed?: Maybe<PlayerStat>;
  /** The number of matches that the player played in that were won */
  matchesWon?: Maybe<PlayerStat>;
  /** The number of votes the player has received. */
  votesReceived?: Maybe<PlayerStat>;
  /** The percentage of matches that the player played in that were won */
  winRate?: Maybe<PlayerStat>;
};


export type PlayerStatsVotesReceivedArgs = {
  type?: Maybe<Scalars['String']>;
};

export type PlayerVoteCount = {
  player: Player;
  voteCount: Scalars['Int'];
  voteType: VoteType;
};

export type Position = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  t: Scalars['DateTime'];
};

export type PublicTeam = {
  /** Some blurb about the team */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Is the current user a captain (or co-captain) of the team? */
  isCaptain: Scalars['Boolean'];
  /** Is the current user the team owner? */
  isOwner: Scalars['Boolean'];
  /** The kits that the team plays in */
  kitItems: Array<KitItem>;
  /** A link to a team's league */
  leagueLink?: Maybe<Scalars['String']>;
  /** Where the team is based. E.g. London, UK */
  location?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** The link used to pay the team captain */
  paymentLink?: Maybe<Scalars['String']>;
  /** The player for the current user */
  player?: Maybe<Player>;
  players: Array<Player>;
  user: User;
  /** The types of votes that the team supports */
  voteTypes: Array<VoteType>;
};

export type PushSubscription = Node & {
  endpoint: Scalars['String'];
  /** The ID of an object */
  id: Scalars['ID'];
};

export type ReferralCode = Node & {
  code: Scalars['String'];
  /** The ID of an object */
  id: Scalars['ID'];
  user: User;
};

export type RefreshSessionResponse = {
  entity?: Maybe<Session>;
};

export type RegisterResponse = {
  entity?: Maybe<Session>;
  validation?: Maybe<Array<Validation>>;
};

/** A user whose information is exposed when requesting access to a resource. E.g. joining a team */
export type RequestUser = {
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ReviewClubRequestResponse = {
  entity?: Maybe<Team>;
  validation?: Maybe<Array<Validation>>;
};

export type RootMutationType = {
  /** Approve a request to connect to a club */
  approveClubRequest?: Maybe<ReviewClubRequestResponse>;
  /** Approve a users's request to join a team, assigning them to a player */
  approveTeamRequest?: Maybe<ApproveTeamRequestResponse>;
  /** Assign the player to a given user. Any players on the same team already assigned to the given user will be unassigned. */
  assignPlayerToUser?: Maybe<CreatePlayerResponse>;
  /** Attach an image to a match */
  attachMatchImage?: Maybe<CreateMatchResponse>;
  /** Close match voting. This will allow the votes that have been cast to be read and prevent further votes from being cast. */
  closeMatchVoting?: Maybe<CreateMatchResponse>;
  /** Confirm a user's email address using a confirmation token */
  confirmEmailAddress?: Maybe<ValidationResponse>;
  /** Create a new user account and login */
  createAccount?: Maybe<RegisterResponse>;
  /**
   * Create a card recipient for a match
   * @deprecated Use `createMatchEvent`
   */
  createCardRecipient?: Maybe<CreateCardRecipientResponse>;
  /** Create a checkout session for subscribing to a plan */
  createCheckoutSession?: Maybe<CreateCheckoutSessionResponse>;
  createClub?: Maybe<CreateClubResponse>;
  /** Submit a request to connect a team to a club */
  createClubRequest?: Maybe<CreateClubRequestResponse>;
  /** Create a new kit item */
  createKitItemForTeam?: Maybe<CreateKitItemResponse>;
  /** Create a new match */
  createMatch?: Maybe<CreateMatchResponse>;
  /** Create a match event for a match */
  createMatchEvent?: Maybe<CreateMatchEventResponse>;
  /** Create a new player */
  createPlayer?: Maybe<CreatePlayerResponse>;
  createPortalSession?: Maybe<CreatePortalSessionResponse>;
  createPushSubscription?: Maybe<ValidationResponse>;
  /** Create a season for a team */
  createSeason?: Maybe<CreateSeasonResponse>;
  /** Create a new team */
  createTeam?: Maybe<TeamResponse>;
  /** Submit a request to join a team */
  createTeamRequest?: Maybe<CreateTeamRequestResponse>;
  createVote?: Maybe<VoteResponse>;
  createVoteType?: Maybe<CreateVoteTypeResponse>;
  /** Decline a request to connect to a club */
  declineClubRequest?: Maybe<ReviewClubRequestResponse>;
  /** Decline a users's request to join a team */
  declineTeamRequest?: Maybe<ValidationResponse>;
  /**
   * Delete a card recipient
   * @deprecated Use `deleteMatchEvent`
   */
  deleteCardRecipient?: Maybe<DeleteCardRecipientResponse>;
  deleteFitnessDeviceUser?: Maybe<User>;
  /** Delete an image */
  deleteImage?: Maybe<DeleteImageResponse>;
  deleteKitItem?: Maybe<DeleteKitItemResponse>;
  /** Delete a match */
  deleteMatch?: Maybe<DeleteMatchResponse>;
  /** Delete a match event */
  deleteMatchEvent?: Maybe<DeleteMatchEventResponse>;
  /** Deletes a player */
  deletePlayer?: Maybe<DeletePlayerResponse>;
  deletePushSubscription?: Maybe<DeletePushSubscriptionResponse>;
  /** Delete a season */
  deleteSeason?: Maybe<DeleteSeasonResponse>;
  detachPlayerFromUser?: Maybe<CreatePlayerResponse>;
  /** Disable totp */
  disableTotp?: Maybe<UpdateUserResponse>;
  /** Enable totp */
  enableTotp?: Maybe<UpdateUserResponse>;
  forgotPassword?: Maybe<ValidationResponse>;
  /** Obtain an auth URL */
  getFitnessDataAuthUrl?: Maybe<FitnessDataAuthUrlResponse>;
  /** Get a signed URL to use to upload a file */
  getUploadUrl?: Maybe<GetUploadUrlResponse>;
  /** Create a new session */
  login?: Maybe<LoginResponse>;
  logout?: Maybe<LogoutResponse>;
  mergePlayers?: Maybe<Player>;
  /** Set the players for a match */
  pickPlayers?: Maybe<PickPlayersResponse>;
  refreshSession?: Maybe<RefreshSessionResponse>;
  /** Reprocess a given activity using the raw date stored in the DB. Also attemps to re-link it to a match. */
  reprocessActivity?: Maybe<MatchActivity>;
  /** Request an email address confirmation email */
  requestEmailConfirmationEmail?: Maybe<Scalars['Boolean']>;
  /** Send a help contact message */
  sendContact?: Maybe<ValidationResponse>;
  sendTestPushMessage: Scalars['String'];
  /** Set the kit for a match */
  setKit?: Maybe<SetKitResponse>;
  /** Sets a player availability for a match */
  setMatchAvailability?: Maybe<SetMatchAvailabilityResponse>;
  /** Set image to be the match's highlight image */
  setMatchHighlightImage?: Maybe<SetImageOrderResponse>;
  /**
   * Set the location for a match
   * @deprecated Use updateMatch
   */
  setMatchLocation?: Maybe<CreateMatchResponse>;
  /** Set notification preference for the current user */
  setNotificationPreference: SetNotificationResponse;
  /** Set the co-captains for a match */
  setTeamCoCaptains?: Maybe<TeamResponse>;
  /** Share match details by sending them in an email */
  shareMatchDetails?: Maybe<ShareMatchResponse>;
  /** Update an image */
  updateImage?: Maybe<UpdateImageResponse>;
  /** Update an existing kit item */
  updateKitItem?: Maybe<CreateKitItemResponse>;
  /** Update an existing match */
  updateMatch?: Maybe<CreateMatchResponse>;
  /** Update user's password */
  updatePassword?: Maybe<ValidationResponse>;
  /** Update an existing player */
  updatePlayer?: Maybe<CreatePlayerResponse>;
  /** Update a season */
  updateSeason?: Maybe<CreateSeasonResponse>;
  updateTeam?: Maybe<TeamResponse>;
  /** Update user details */
  updateUser?: Maybe<UpdateUserResponse>;
  updateVoteType?: Maybe<UpdateVoteTypeResponse>;
  verifyMfa?: Maybe<VerifyMfaResponse>;
};


export type RootMutationTypeApproveClubRequestArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeApproveTeamRequestArgs = {
  playerId?: Maybe<Scalars['ID']>;
  playerName?: Maybe<Scalars['String']>;
  requestId: Scalars['ID'];
};


export type RootMutationTypeAssignPlayerToUserArgs = {
  input: AssignPlayerToUserInput;
};


export type RootMutationTypeAttachMatchImageArgs = {
  id: Scalars['UUID'];
  image: ImageInput;
};


export type RootMutationTypeCloseMatchVotingArgs = {
  id: Scalars['UUID'];
};


export type RootMutationTypeConfirmEmailAddressArgs = {
  token: Scalars['String'];
};


export type RootMutationTypeCreateAccountArgs = {
  input: CreateAccountInput;
};


export type RootMutationTypeCreateCardRecipientArgs = {
  input: CreateCardRecipientInput;
};


export type RootMutationTypeCreateCheckoutSessionArgs = {
  input: CreateSessionInput;
};


export type RootMutationTypeCreateClubArgs = {
  input: CreateClubInput;
};


export type RootMutationTypeCreateClubRequestArgs = {
  input: CreateClubRequestInput;
};


export type RootMutationTypeCreateKitItemForTeamArgs = {
  input: CreateKitItemInput;
  teamId: Scalars['ID'];
};


export type RootMutationTypeCreateMatchArgs = {
  input: CreateMatchInput;
};


export type RootMutationTypeCreateMatchEventArgs = {
  input: CreateMatchEventInput;
};


export type RootMutationTypeCreatePlayerArgs = {
  name: Scalars['String'];
  teamId: Scalars['ID'];
};


export type RootMutationTypeCreatePortalSessionArgs = {
  input: CreatePortalSessionInput;
};


export type RootMutationTypeCreatePushSubscriptionArgs = {
  input: CreatePushSubscriptionInput;
};


export type RootMutationTypeCreateSeasonArgs = {
  input: CreateSeasonInput;
};


export type RootMutationTypeCreateTeamArgs = {
  input: CreateTeamInput;
};


export type RootMutationTypeCreateTeamRequestArgs = {
  teamId: Scalars['ID'];
};


export type RootMutationTypeCreateVoteArgs = {
  input: CreateVoteInput;
};


export type RootMutationTypeCreateVoteTypeArgs = {
  input: CreateVoteTypeInput;
};


export type RootMutationTypeDeclineClubRequestArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeclineTeamRequestArgs = {
  requestId: Scalars['ID'];
};


export type RootMutationTypeDeleteCardRecipientArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteFitnessDeviceUserArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteImageArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteKitItemArgs = {
  id: Scalars['UUID'];
};


export type RootMutationTypeDeleteMatchArgs = {
  id: Scalars['UUID'];
};


export type RootMutationTypeDeleteMatchEventArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeletePlayerArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeletePushSubscriptionArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDeleteSeasonArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeDetachPlayerFromUserArgs = {
  input: DetachPlayerFromUserInput;
};


export type RootMutationTypeEnableTotpArgs = {
  input: EnableTotpInput;
};


export type RootMutationTypeForgotPasswordArgs = {
  email: Scalars['String'];
};


export type RootMutationTypeGetFitnessDataAuthUrlArgs = {
  input: FitnessDataAuthUrlInput;
};


export type RootMutationTypeGetUploadUrlArgs = {
  input: GetUploadUrlInput;
};


export type RootMutationTypeLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootMutationTypeMergePlayersArgs = {
  source: Scalars['ID'];
  target: Scalars['ID'];
};


export type RootMutationTypePickPlayersArgs = {
  input: PickPlayersInput;
};


export type RootMutationTypeReprocessActivityArgs = {
  id: Scalars['ID'];
};


export type RootMutationTypeSendContactArgs = {
  email: Scalars['String'];
  message: Scalars['String'];
};


export type RootMutationTypeSendTestPushMessageArgs = {
  input: TestPushMessageInput;
};


export type RootMutationTypeSetKitArgs = {
  input: SetKitInput;
};


export type RootMutationTypeSetMatchAvailabilityArgs = {
  input: SetMatchAvailabilityInput;
};


export type RootMutationTypeSetMatchHighlightImageArgs = {
  imageId: Scalars['ID'];
};


export type RootMutationTypeSetMatchLocationArgs = {
  location: LocationInput;
  matchId: Scalars['UUID'];
};


export type RootMutationTypeSetNotificationPreferenceArgs = {
  enabled: Scalars['Boolean'];
  notificationId: Scalars['ID'];
};


export type RootMutationTypeSetTeamCoCaptainsArgs = {
  input: SetTeamCoCaptainsInput;
};


export type RootMutationTypeShareMatchDetailsArgs = {
  input: ShareMatchInput;
};


export type RootMutationTypeUpdateImageArgs = {
  id: Scalars['ID'];
  input: UpdateImageInput;
};


export type RootMutationTypeUpdateKitItemArgs = {
  id: Scalars['UUID'];
  input: UpdateKitItemInput;
};


export type RootMutationTypeUpdateMatchArgs = {
  id: Scalars['UUID'];
  input: UpdateMatchInput;
};


export type RootMutationTypeUpdatePasswordArgs = {
  password: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUpdatePlayerArgs = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type RootMutationTypeUpdateSeasonArgs = {
  id: Scalars['ID'];
  input: UpdateSeasonInput;
};


export type RootMutationTypeUpdateTeamArgs = {
  id: Scalars['ID'];
  input: UpdateTeamInput;
};


export type RootMutationTypeUpdateUserArgs = {
  input: UpdateUserInput;
};


export type RootMutationTypeUpdateVoteTypeArgs = {
  id: Scalars['ID'];
  input: UpdateVoteTypeInput;
};


export type RootMutationTypeVerifyMfaArgs = {
  input: VerifyMfaInput;
};

export type RootQueryType = {
  /** Get a club by its ID */
  club?: Maybe<Club>;
  /** Get a list of players connected to the current user */
  connectedPlayers?: Maybe<PlayerConnection>;
  /** Get a kit item by its ID */
  kitItem?: Maybe<KitItem>;
  /** Get a match by its ID */
  match?: Maybe<Match>;
  matchActivity?: Maybe<MatchActivity>;
  notifications: Array<Notification>;
  /** Get the available plans for subscribing to Matchhaus */
  plans?: Maybe<Array<Plan>>;
  /** Get a player by its ID */
  player?: Maybe<Player>;
  /** Get the public representation of a team by its ID */
  publicTeam?: Maybe<PublicTeam>;
  /** Get a player by its ID */
  referralCode?: Maybe<ReferralCode>;
  searchClubs: Array<Club>;
  /** Search for a team by its name */
  searchTeams: Array<PublicTeam>;
  season?: Maybe<Season>;
  /** Get a team by its ID */
  team?: Maybe<Team>;
  /** Get outstanding requests to join a team for the current user */
  teamRequests: Array<TeamRequest>;
  /** Get a TOTP secret */
  totpSecret?: Maybe<TotpSecret>;
  /** Retrieve a user from a JWT */
  user?: Maybe<User>;
};


export type RootQueryTypeClubArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeConnectedPlayersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


export type RootQueryTypeKitItemArgs = {
  id: Scalars['UUID'];
};


export type RootQueryTypeMatchArgs = {
  id: Scalars['UUID'];
};


export type RootQueryTypeMatchActivityArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePlayerArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypePublicTeamArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeReferralCodeArgs = {
  code: Scalars['String'];
};


export type RootQueryTypeSearchClubsArgs = {
  query: Scalars['String'];
};


export type RootQueryTypeSearchTeamsArgs = {
  query: Scalars['String'];
};


export type RootQueryTypeSeasonArgs = {
  id: Scalars['ID'];
};


export type RootQueryTypeTeamArgs = {
  id: Scalars['ID'];
};

export type Scorer = {
  id: Scalars['ID'];
  match: Match;
  player: Player;
};

export type Season = Node & {
  endedAt: Scalars['DateTime'];
  /** The ID of an object */
  id: Scalars['ID'];
  name: Scalars['String'];
  startedAt: Scalars['DateTime'];
  team: Team;
};

/** A session for a logged in user */
export type Session = {
  accessToken: Scalars['String'];
  user: User;
};

export type SetImageOrderResponse = {
  entity?: Maybe<MatchImage>;
  validation?: Maybe<Array<Validation>>;
};

export type SetKitInput = {
  /** A list of IDs for the kit items being picked */
  kitItemIds: Array<Scalars['UUID']>;
  /** The ID of the match for which the kit is being picked */
  matchId: Scalars['UUID'];
};

export type SetKitResponse = {
  /** The match for which the kit has been set */
  entity?: Maybe<Match>;
  validation?: Maybe<Array<Validation>>;
};

export type SetMatchAvailabilityInput = {
  matchId: Scalars['UUID'];
  playerId: Scalars['ID'];
  status: AvailabilityStatus;
};

export type SetMatchAvailabilityResponse = {
  entity?: Maybe<Availability>;
  validation?: Maybe<Array<Validation>>;
};

export type SetNotificationResponse = {
  notifications: Array<Notification>;
};

export type SetTeamCoCaptainsInput = {
  /** The ID of the team for which the co-captains are being assigned */
  teamId: Scalars['ID'];
  /** A list of IDs for the users being picked */
  userIds: Array<Scalars['ID']>;
};

export type ShareMatchInput = {
  /** Any extra comments or details to share. Max 1000 characters. */
  comment?: Maybe<Scalars['String']>;
  /** The email address to send the match details to */
  email: Scalars['String'];
  /** The ID of the match to share */
  id: Scalars['UUID'];
};

export type ShareMatchResponse = {
  validation?: Maybe<Array<Validation>>;
};

export type SortDirection = 
  | 'ASC'
  | 'DESC';

export type Team = {
  club?: Maybe<Club>;
  coCaptains?: Maybe<Array<User>>;
  createdAt: Scalars['DateTime'];
  /** Some blurb about the team */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Is the current user a captain (or co-captain) of the team? */
  isCaptain: Scalars['Boolean'];
  /** Is the current user the team owner? */
  isOwner: Scalars['Boolean'];
  /** The kits that the team plays in */
  kitItems: Array<KitItem>;
  leaderboards?: Maybe<TeamLeaderboards>;
  /** A link to a team's league */
  leagueLink?: Maybe<Scalars['String']>;
  /** Where the team is based. E.g. London, UK */
  location?: Maybe<Scalars['String']>;
  matches?: Maybe<MatchConnection>;
  /** The team name */
  name: Scalars['String'];
  /** The link used to pay the team captain */
  paymentLink?: Maybe<Scalars['String']>;
  /** The player for the current user */
  player?: Maybe<Player>;
  /** The players who belong to the team, ordered by last played */
  players: Array<Player>;
  recentLocations?: Maybe<Array<Location>>;
  requests: Array<TeamRequest>;
  /** The seasons for the team */
  seasons: Array<Season>;
  /** Statistics for the team */
  stats: TeamStats;
  /** The user who owns the team */
  user: User;
  /** The types of votes that the team supports */
  voteTypes: Array<VoteType>;
};


export type TeamMatchesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<SortDirection>;
  first?: Maybe<Scalars['Int']>;
  ignoreStatsExcludedMatches?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
};

export type TeamLeaderboards = {
  assists: Array<LeaderboardItem>;
  /** The number of goals conceded per game for the matches each player played in */
  averageGoalsAgainst: Array<LeaderboardItem>;
  /** The number of goals scored per game for the matches each player played in */
  averageGoalsFor: Array<LeaderboardItem>;
  cleanSheets: Array<LeaderboardItem>;
  cleanSheetsRate: Array<LeaderboardItem>;
  goals: Array<LeaderboardItem>;
  /** The number of goals conceded in the matches each player played in */
  goalsAgainst: Array<LeaderboardItem>;
  /** The number of goals scored in the matches each player played in */
  goalsFor: Array<LeaderboardItem>;
  goalsPerGame: Array<LeaderboardItem>;
  matchesDrawn: Array<LeaderboardItem>;
  matchesLost: Array<LeaderboardItem>;
  matchesPlayed: Array<LeaderboardItem>;
  matchesWon: Array<LeaderboardItem>;
  /** Leaderboard of top vote recipients for a given vote type */
  votes: Array<LeaderboardItem>;
  winRate: Array<LeaderboardItem>;
};


export type TeamLeaderboardsAssistsArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsAverageGoalsAgainstArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsAverageGoalsForArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsCleanSheetsArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsCleanSheetsRateArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsGoalsArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsGoalsAgainstArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsGoalsForArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsGoalsPerGameArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsMatchesDrawnArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsMatchesLostArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsMatchesPlayedArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsMatchesWonArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type TeamLeaderboardsVotesArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  voteType: Scalars['String'];
};


export type TeamLeaderboardsWinRateArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type TeamRequest = {
  id: Scalars['ID'];
  requestedAt: Scalars['DateTime'];
  team: PublicTeam;
  user: RequestUser;
};

export type TeamResponse = {
  entity?: Maybe<Team>;
  validation?: Maybe<Array<Validation>>;
};

export type TeamStats = {
  /** The number of matches played by the team */
  matchesPlayed: Scalars['Int'];
};

export type TestPushMessageInput = {
  message: Scalars['String'];
  title: Scalars['String'];
};


export type TotpSecret = {
  secret: Scalars['String'];
  uri: Scalars['String'];
};

export type UpdateImageInput = {
  caption?: Maybe<Scalars['String']>;
};

export type UpdateImageResponse = {
  entity?: Maybe<Image>;
  validation?: Maybe<Array<Validation>>;
};

export type UpdateKitItemInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateMatchInput = {
  atHome?: Maybe<Scalars['Boolean']>;
  excludedFromStats?: Maybe<Scalars['Boolean']>;
  goalsAgainst?: Maybe<Scalars['Int']>;
  goalsFor?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationInput>;
  matchReport?: Maybe<Scalars['String']>;
  meetAt?: Maybe<Scalars['DateTime']>;
  opponent?: Maybe<Scalars['String']>;
  playedAt?: Maybe<Scalars['DateTime']>;
  timeZone?: Maybe<Scalars['TimeZone']>;
};

export type UpdateSeasonInput = {
  endedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  startedAt?: Maybe<Scalars['DateTime']>;
};

export type UpdateTeamInput = {
  /** Some blurb about the team */
  description?: Maybe<Scalars['String']>;
  /** A link to a team's league */
  leagueLink?: Maybe<Scalars['String']>;
  /** Where the team is based. E.g. London, UK */
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** The link used to pay the team captain */
  paymentLink?: Maybe<Scalars['String']>;
  /** User ID of team new team owner */
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateUserInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  profileImage?: Maybe<ImageInput>;
  settings?: Maybe<UserSettingsInput>;
};

export type UpdateUserResponse = {
  entity?: Maybe<User>;
  validation?: Maybe<Array<Validation>>;
};

export type UpdateVoteTypeInput = {
  emoji?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  /** Control the order in which the vote type is returned in a list */
  position?: Maybe<Scalars['Int']>;
};

export type UpdateVoteTypeResponse = {
  entity?: Maybe<VoteType>;
  validation?: Maybe<Array<Validation>>;
};

export type UploadContext = 
  | 'AVATAR'
  | 'MATCH_IMAGE';

export type User = {
  billingId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailConfirmed: Scalars['Boolean'];
  experiments?: Maybe<Array<Experiment>>;
  fitnessDeviceUsers: Array<FitnessDeviceUser>;
  id: Scalars['ID'];
  isCurrentUser: Scalars['Boolean'];
  isPremium: Scalars['Boolean'];
  joinedAt: Scalars['DateTime'];
  matches?: Maybe<MatchConnection>;
  name: Scalars['String'];
  players?: Maybe<Array<Player>>;
  profileImage?: Maybe<Image>;
  pushSubscriptions?: Maybe<Array<PushSubscription>>;
  referralCode?: Maybe<Scalars['String']>;
  settings?: Maybe<UserSettings>;
  subscriptionStatus: Scalars['String'];
  /** Outstanding requests to join a team for the player */
  teamRequests?: Maybe<Array<TeamRequest>>;
  teams?: Maybe<Array<Team>>;
  totpEnabled: Scalars['Boolean'];
};


export type UserMatchesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  direction?: Maybe<SortDirection>;
  first?: Maybe<Scalars['Int']>;
  ignoreStatsExcludedMatches?: Maybe<Scalars['Boolean']>;
  last?: Maybe<Scalars['Int']>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
};

export type UserSettings = {
  dismissedAlerts?: Maybe<Array<Scalars['String']>>;
  hideClub?: Maybe<Scalars['Boolean']>;
  onboardedTeams?: Maybe<Array<Scalars['String']>>;
  statsDate?: Maybe<Scalars['DateTime']>;
};

export type UserSettingsInput = {
  defaultTeamId?: Maybe<Scalars['Int']>;
  dismissedAlerts?: Maybe<Array<Scalars['String']>>;
  hideClub?: Maybe<Scalars['Boolean']>;
  onboardedTeams?: Maybe<Array<Scalars['String']>>;
  statsDate?: Maybe<Scalars['DateTime']>;
};


/** A validation error */
export type Validation = {
  key: Scalars['String'];
  reason: Scalars['String'];
};

export type ValidationResponse = {
  validation?: Maybe<Array<Validation>>;
};

export type VerifyMfaInput = {
  code: Scalars['String'];
  mfaMethod: MfaMethod;
  microSession: Scalars['String'];
};

export type VerifyMfaResponse = {
  error?: Maybe<MfaError>;
  session?: Maybe<Session>;
};

export type Vote = {
  comment?: Maybe<Scalars['String']>;
  commentHtml?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  match: Match;
  player: Player;
  voteType: VoteType;
};

export type VoteConnection = {
  edges: Array<VoteEdge>;
  pageInfo: PageInfo;
};

export type VoteEdge = {
  cursor?: Maybe<Scalars['String']>;
  node: Vote;
};

export type VoteResponse = {
  entity?: Maybe<Vote>;
  validation?: Maybe<Array<Validation>>;
};

export type VoteType = {
  emoji: Scalars['String'];
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
};

export type RequestEmailAddressConfirmationEmailMutationVariables = {};


export type RequestEmailAddressConfirmationEmailMutation = Pick<RootMutationType, 'requestEmailConfirmationEmail'>;

export type FullUserFragment = (
  Pick<User, 'id' | 'email' | 'name' | 'referralCode' | 'subscriptionStatus' | 'isPremium' | 'billingId' | 'emailConfirmed' | 'totpEnabled'>
  & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>>, settings?: Maybe<Pick<UserSettings, 'hideClub' | 'statsDate' | 'onboardedTeams' | 'dismissedAlerts'>>, fitnessDeviceUsers: Array<Pick<FitnessDeviceUser, 'id'>>, teams?: Maybe<Array<Pick<Team, 'id' | 'name' | 'isOwner'>>>, experiments?: Maybe<Array<Pick<Experiment, 'id' | 'name'>>>, teamRequests?: Maybe<Array<(
    Pick<TeamRequest, 'id'>
    & { team: Pick<PublicTeam, 'id' | 'name'> }
  )>> }
);

export type UserQueryVariables = {};


export type UserQuery = { user?: Maybe<FullUserFragment> };

export type LogoutMutationVariables = {};


export type LogoutMutation = { logout?: Maybe<Pick<LogoutResponse, 'result'>> };

export type SetDefaultTeamMutationVariables = {
  teamId: Scalars['Int'];
};


export type SetDefaultTeamMutation = { updateUser?: Maybe<{ entity?: Maybe<Pick<User, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type FullTeamFragment = (
  Pick<Team, 'isOwner' | 'isCaptain'>
  & { player?: Maybe<(
    Pick<Player, 'id' | 'name'>
    & { user?: Maybe<(
      Pick<User, 'id' | 'name' | 'isCurrentUser'>
      & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
    )> }
  )>, requests: Array<TeamRequestFragmentFragment>, club?: Maybe<Pick<Club, 'id' | 'name'>>, voteTypes: Array<VoteTypeFragmentFragment>, stats: Pick<TeamStats, 'matchesPlayed'> }
  & TeamFragmentFragment
);

export type TeamQueryVariables = {
  id: Scalars['ID'];
};


export type TeamQuery = { team?: Maybe<FullTeamFragment> };

export type UserPlayersQueryVariables = {
  first: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
};


export type UserPlayersQuery = { connectedPlayers?: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>, edges: Array<(
      Pick<PlayerEdge, 'cursor'>
      & { node: PlayerFragmentFragment }
    )> }> };

export type DeleteImageMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteImageMutation = { deleteImage?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type DetachPlayerFromUserMutationVariables = {
  input: DetachPlayerFromUserInput;
};


export type DetachPlayerFromUserMutation = { detachPlayerFromUser?: Maybe<{ entity?: Maybe<Pick<Player, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type PlayerUserFragmentFragment = (
  Pick<User, 'id' | 'name' | 'isCurrentUser' | 'isPremium'>
  & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
);

export type PlayerFragmentFragment = (
  Pick<Player, 'id' | 'name' | 'isArchived'>
  & { user?: Maybe<PlayerUserFragmentFragment>, team: Pick<PublicTeam, 'id' | 'name'> }
);

export type TeamRequestFragmentFragment = (
  Pick<TeamRequest, 'id' | 'requestedAt'>
  & { user: Pick<RequestUser, 'id' | 'name' | 'email'> }
);

export type ScorerFragmentFragment = (
  Pick<Scorer, 'id'>
  & { player: Pick<Player, 'id' | 'name'> }
);

export type CardRecipientFragmentFragment = (
  Pick<CardRecipient, 'id' | 'cardType'>
  & { player: Pick<Player, 'id' | 'name'> }
);

export type LeaderboardItemFragmentFragment = (
  Pick<LeaderboardItem, 'score' | 'rank'>
  & { player: (
    Pick<Player, 'id' | 'name' | 'isArchived'>
    & { user?: Maybe<PlayerUserFragmentFragment> }
  ) }
);

export type KitItemFragmentFragment = (
  Pick<KitItem, 'id' | 'name'>
  & { team: Pick<Team, 'id'> }
);

export type MatchListItemFragment = (
  Pick<Match, 'id' | 'opponent' | 'playedAt' | 'meetAt' | 'votingStatus' | 'atHome' | 'excludedFromStats' | 'timeZone' | 'goalsAgainst' | 'goalsFor' | 'availability'>
  & { location?: Maybe<Pick<Location, 'address' | 'lat' | 'lng'>>, image?: Maybe<Pick<Image, 'id' | 'url' | 'caption'>>, team: Pick<Team, 'name' | 'id'>, scorers: Array<ScorerFragmentFragment> }
);

export type PlayerVoteCountFragmentFragment = (
  Pick<PlayerVoteCount, 'voteCount'>
  & { voteType: VoteTypeFragmentFragment, player: (
    Pick<Player, 'id' | 'name' | 'isArchived'>
    & { user?: Maybe<PlayerUserFragmentFragment> }
  ) }
);

export type VoteTypeFragmentFragment = Pick<VoteType, 'id' | 'name' | 'enabled' | 'emoji' | 'position'>;

export type VoteFragmentFragment = (
  Pick<Vote, 'id' | 'comment' | 'commentHtml'>
  & { player: PlayerFragmentFragment, voteType: VoteTypeFragmentFragment }
);

export type MatchListEdgeFragment = (
  Pick<MatchEdge, 'cursor'>
  & { node: MatchListItemFragment }
);

export type TeamFragmentFragment = (
  Pick<Team, 'id' | 'name' | 'location' | 'description' | 'createdAt' | 'isOwner' | 'paymentLink' | 'leagueLink'>
  & { players: Array<PlayerFragmentFragment>, kitItems: Array<Pick<KitItem, 'id' | 'name'>> }
);

export type MatchListQueryVariables = {
  id: Scalars['ID'];
  direction?: Maybe<SortDirection>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  ignoreStatsExcludedMatches?: Maybe<Scalars['Boolean']>;
};


export type MatchListQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { matches?: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>, edges: Array<MatchListEdgeFragment> }> }
  )> };

export type MatchActivityQueryVariables = {
  id: Scalars['ID'];
};


export type MatchActivityQuery = { matchActivity?: Maybe<(
    Pick<MatchActivity, 'id'>
    & { match?: Maybe<Pick<Match, 'id' | 'opponent'>>, player?: Maybe<(
      Pick<Player, 'id' | 'name' | 'isArchived'>
      & { user?: Maybe<PlayerUserFragmentFragment> }
    )>, positions: Array<Pick<Position, 't' | 'lat' | 'lng'>>, summaryData: Pick<MatchActivityData, 'calories' | 'device' | 'distance' | 'steps' | 'avgSpeed' | 'avgHr' | 'maxSpeed'> }
  )> };

export type CreateAccountMutationVariables = {
  input: CreateAccountInput;
};


export type CreateAccountMutation = { createAccount?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<{ user: Pick<User, 'name' | 'id'> }> }> };

export type LoginMutationVariables = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type LoginMutation = { login?: Maybe<{ error?: Maybe<(
      { __typename: 'InvalidCredentialsLoginError' }
      & Pick<InvalidCredentialsLoginError, 'errorType'>
    ) | (
      { __typename: 'MfaNeededLoginError' }
      & Pick<MfaNeededLoginError, 'errorType' | 'mfaToken'>
    )>, session?: Maybe<{ user: Pick<User, 'id' | 'name'> }> }> };

export type GetTotpSecretQueryVariables = {};


export type GetTotpSecretQuery = { totpSecret?: Maybe<Pick<TotpSecret, 'uri' | 'secret'>> };

export type VerifyMfaMutationVariables = {
  input: VerifyMfaInput;
};


export type VerifyMfaMutation = { verifyMfa?: Maybe<{ error?: Maybe<(
      { __typename: 'MfaError' }
      & Pick<MfaError, 'errorType'>
    )>, session?: Maybe<(
      Pick<Session, 'accessToken'>
      & { user: Pick<User, 'id'> }
    )> }> };

export type MatchHighlightItemFragment = (
  Pick<Match, 'id' | 'opponent' | 'playedAt' | 'matchReport'>
  & { images: Array<Pick<Image, 'id' | 'url' | 'caption'>>, team: (
    Pick<Team, 'id' | 'name' | 'isOwner' | 'isCaptain'>
    & { player?: Maybe<Pick<Player, 'id'>> }
  ), voteResult?: Maybe<{ winners: Array<PlayerVoteCountFragmentFragment> }> }
);

export type ClubMatchHighlightsQueryVariables = {
  clubId: Scalars['ID'];
  direction?: Maybe<SortDirection>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<ClubMatchFilterType>;
};


export type ClubMatchHighlightsQuery = { club?: Maybe<(
    Pick<Club, 'id'>
    & { matches?: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>, edges: Array<(
        Pick<MatchEdge, 'cursor'>
        & { node: MatchHighlightItemFragment }
      )> }> }
  )> };

export type ApproveClubRequestMutationVariables = {
  id: Scalars['ID'];
};


export type ApproveClubRequestMutation = { approveClubRequest?: Maybe<{ entity?: Maybe<Pick<Team, 'id' | 'name'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type ClubPlayerAvailabilitiesFragment = (
  Pick<Availability, 'id' | 'status'>
  & { player: (
    Pick<Player, 'id' | 'name' | 'isArchived'>
    & { user?: Maybe<(
      Pick<User, 'id'>
      & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
    )> }
  ) }
);

export type ClubAvailabilitiesQueryVariables = {
  clubId: Scalars['ID'];
  direction?: Maybe<SortDirection>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<ClubMatchFilterType>;
};


export type ClubAvailabilitiesQuery = { club?: Maybe<(
    Pick<Club, 'id'>
    & { matches?: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>, edges: Array<(
        Pick<MatchEdge, 'cursor'>
        & { node: (
          Pick<Match, 'id' | 'opponent' | 'atHome' | 'playedAt' | 'timeZone'>
          & { team: Pick<Team, 'id' | 'name'>, availabilities: Array<ClubPlayerAvailabilitiesFragment> }
        ) }
      )> }> }
  )> };

export type ClubMatchesQueryVariables = {
  clubId: Scalars['ID'];
  direction?: Maybe<SortDirection>;
  playedAfter?: Maybe<Scalars['DateTime']>;
  playedBefore?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  filter?: Maybe<ClubMatchFilterType>;
};


export type ClubMatchesQuery = { club?: Maybe<(
    Pick<Club, 'id'>
    & { matches?: Maybe<{ pageInfo: Pick<PageInfo, 'hasNextPage' | 'endCursor' | 'startCursor' | 'hasPreviousPage'>, edges: Array<MatchListEdgeFragment> }> }
  )> };

export type FullClubFragment = (
  Pick<Club, 'id' | 'name' | 'location'>
  & { teams: Array<Pick<Team, 'id' | 'name' | 'isOwner'>>, clubRequests?: Maybe<Array<(
    Pick<ClubRequest, 'id' | 'requestedAt'>
    & { team: Pick<PublicTeam, 'id' | 'name' | 'location'> }
  )>> }
);

export type ClubQueryVariables = {
  id: Scalars['ID'];
};


export type ClubQuery = { club?: Maybe<FullClubFragment> };

export type DeclineClubRequestMutationVariables = {
  id: Scalars['ID'];
};


export type DeclineClubRequestMutation = { declineClubRequest?: Maybe<{ entity?: Maybe<Pick<Team, 'id' | 'name'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type DeleteFitnessDeviceMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteFitnessDeviceMutation = { deleteFitnessDeviceUser?: Maybe<(
    Pick<User, 'id'>
    & { fitnessDeviceUsers: Array<Pick<FitnessDeviceUser, 'id' | 'provider'>> }
  )> };

export type GetTerraSessionUrlMutationVariables = {
  input: FitnessDataAuthUrlInput;
};


export type GetTerraSessionUrlMutation = { getFitnessDataAuthUrl?: Maybe<Pick<FitnessDataAuthUrlResponse, 'sessionUrl'>> };

export type UserFitnessDevicesQueryVariables = {};


export type UserFitnessDevicesQuery = { user?: Maybe<(
    Pick<User, 'id'>
    & { fitnessDeviceUsers: Array<Pick<FitnessDeviceUser, 'id' | 'provider'>> }
  )> };

export type CreateTeamRequestMutationVariables = {
  teamId: Scalars['ID'];
};


export type CreateTeamRequestMutation = { createTeamRequest?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type TeamResultFragment = (
  Pick<PublicTeam, 'name' | 'id' | 'location' | 'description'>
  & { user: Pick<User, 'name'> }
);

export type SearchTeamsQueryVariables = {
  query: Scalars['String'];
};


export type SearchTeamsQuery = { searchTeams: Array<TeamResultFragment> };

export type TeamRequestsQueryVariables = {};


export type TeamRequestsQuery = { teamRequests: Array<(
    Pick<TeamRequest, 'id' | 'requestedAt'>
    & { team: Pick<PublicTeam, 'id' | 'name'> }
  )> };

export type MatchVoteWinnersQueryVariables = {
  matchId: Scalars['UUID'];
};


export type MatchVoteWinnersQuery = { match?: Maybe<(
    Pick<Match, 'id'>
    & { voteResult?: Maybe<{ winners: Array<PlayerVoteCountFragmentFragment> }> }
  )> };

export type MatchActivitiesQueryVariables = {
  matchId: Scalars['UUID'];
};


export type MatchActivitiesQuery = { match?: Maybe<(
    Pick<Match, 'opponent'>
    & { activities?: Maybe<Array<(
      Pick<MatchActivity, 'id'>
      & { summaryData: Pick<MatchActivityData, 'distance'>, player?: Maybe<(
        Pick<Player, 'id' | 'name' | 'isArchived'>
        & { user?: Maybe<PlayerUserFragmentFragment> }
      )> }
    )>> }
  )> };

export type CreateMatchEventMutationVariables = {
  input: CreateMatchEventInput;
};


export type CreateMatchEventMutation = { createMatchEvent?: Maybe<{ entity?: Maybe<(
      Pick<MatchEvent, 'id'>
      & { player: Pick<Player, 'id' | 'name'>, match: Pick<Match, 'id'> }
    )>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type DeleteMatchEventMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteMatchEventMutation = { deleteMatchEvent?: Maybe<(
    Pick<DeleteMatchEventResponse, 'error'>
    & { match?: Maybe<{ scorers: Array<Pick<Scorer, 'id'>> }> }
  )> };

export type MatchEventFragmentFragment = (
  Pick<MatchEvent, 'id' | 'event'>
  & { player: (
    Pick<Player, 'id' | 'name' | 'isArchived'>
    & { user?: Maybe<PlayerUserFragmentFragment> }
  ) }
);

export type MatchStatsQueryVariables = {
  id: Scalars['UUID'];
};


export type MatchStatsQuery = { match?: Maybe<{ events: Array<MatchEventFragmentFragment> }> };

export type AttachMatchImageMutationVariables = {
  id: Scalars['UUID'];
  image: ImageInput;
};


export type AttachMatchImageMutation = { attachMatchImage?: Maybe<{ entity?: Maybe<Pick<Match, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type AvailabilityResponseEntityFragment = (
  Pick<Availability, 'id' | 'status'>
  & { player: Pick<Player, 'id' | 'name'>, match: Pick<Match, 'id'> }
);

export type SetMatchAvailabilityMutationVariables = {
  input: SetMatchAvailabilityInput;
};


export type SetMatchAvailabilityMutation = { setMatchAvailability?: Maybe<{ entity?: Maybe<AvailabilityResponseEntityFragment>, validation?: Maybe<Array<Pick<Validation, 'reason' | 'key'>>> }> };

export type CreateMatchMutationVariables = {
  input: CreateMatchInput;
};


export type CreateMatchMutation = { createMatch?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<Match, 'id' | 'opponent' | 'playedAt'>> }> };

export type DeleteMatchMutationVariables = {
  id: Scalars['UUID'];
};


export type DeleteMatchMutation = { deleteMatch?: Maybe<{ entity?: Maybe<Pick<Team, 'id'>> }> };

export type GetUploadUrlMutationVariables = {
  input: GetUploadUrlInput;
};


export type GetUploadUrlMutation = { getUploadUrl?: Maybe<Pick<GetUploadUrlResponse, 'path' | 'uploadUrl'>> };

export type MatchImagesQueryVariables = {
  id: Scalars['UUID'];
};


export type MatchImagesQuery = { match?: Maybe<(
    Pick<Match, 'id'>
    & { images: Array<Pick<Image, 'id' | 'url'>> }
  )> };

export type MatchPlayerFragment = (
  Pick<Player, 'id' | 'name' | 'isArchived'>
  & { user?: Maybe<(
    Pick<User, 'id' | 'name' | 'isCurrentUser' | 'isPremium'>
    & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
  )> }
);

export type FullMatchFragment = (
  Pick<Match, 'id' | 'opponent' | 'meetAt' | 'playedAt' | 'votingStatus' | 'atHome' | 'timeZone' | 'goalsAgainst' | 'goalsFor' | 'voteCount' | 'excludedFromStats' | 'availability' | 'matchReport' | 'matchReportHtml'>
  & { location?: Maybe<Pick<Location, 'address' | 'lat' | 'lng'>>, image?: Maybe<Pick<Image, 'id' | 'url' | 'caption'>>, team: (
    Pick<Team, 'isOwner' | 'isCaptain' | 'id' | 'name' | 'paymentLink' | 'leagueLink'>
    & { players: Array<PlayerFragmentFragment>, player?: Maybe<(
      Pick<Player, 'id' | 'name'>
      & { user?: Maybe<(
        Pick<User, 'id' | 'isCurrentUser'>
        & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
      )> }
    )>, voteTypes: Array<VoteTypeFragmentFragment> }
  ), players: Array<MatchPlayerFragment>, availabilities: Array<(
    Pick<Availability, 'status'>
    & { player: Pick<Player, 'id' | 'name'> }
  )>, kitItems: Array<Pick<KitItem, 'id' | 'name'>> }
);

export type MatchQueryVariables = {
  id: Scalars['UUID'];
};


export type MatchQuery = { match?: Maybe<FullMatchFragment> };

export type PickPlayersMutationVariables = {
  input: PickPlayersInput;
};


export type PickPlayersMutation = { pickPlayers?: Maybe<{ entity?: Maybe<Pick<Match, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type RecentLocationsQueryVariables = {
  teamId: Scalars['ID'];
};


export type RecentLocationsQuery = { team?: Maybe<{ recentLocations?: Maybe<Array<Pick<Location, 'id' | 'address' | 'lat' | 'lng'>>> }> };

export type SetKitMutationVariables = {
  input: SetKitInput;
};


export type SetKitMutation = { setKit?: Maybe<{ entity?: Maybe<(
      Pick<Match, 'id'>
      & { kitItems: Array<Pick<KitItem, 'id' | 'name'>> }
    )>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type SetMatchHighlightImageMutationVariables = {
  imageId: Scalars['ID'];
};


export type SetMatchHighlightImageMutation = { setMatchHighlightImage?: Maybe<{ entity?: Maybe<Pick<MatchImage, 'id' | 'order'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type SetMatchLocationMutationVariables = {
  matchId: Scalars['UUID'];
  location: LocationInput;
};


export type SetMatchLocationMutation = { setMatchLocation?: Maybe<{ entity?: Maybe<Pick<Match, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type UpdateMatchMutationVariables = {
  id: Scalars['UUID'];
  input: UpdateMatchInput;
};


export type UpdateMatchMutation = { updateMatch?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<Match, 'id' | 'opponent' | 'playedAt' | 'atHome' | 'goalsAgainst' | 'goalsFor'>> }> };

export type ForgotPasswordMutationVariables = {
  email: Scalars['String'];
};


export type ForgotPasswordMutation = { forgotPassword?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type UpdatePasswordMutationVariables = {
  password: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


export type UpdatePasswordMutation = { updatePassword?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type PlayerMatchEventFragment = (
  Pick<PlayerEvent, 'event' | 'eventType' | 'id' | 'count'>
  & { match: Pick<Match, 'id' | 'opponent' | 'playedAt'> }
);

export type PlayerEventsQueryVariables = {
  id: Scalars['ID'];
  first?: Scalars['Int'];
};


export type PlayerEventsQuery = { player?: Maybe<(
    Pick<Player, 'id'>
    & { events?: Maybe<{ edges: Array<{ node: PlayerMatchEventFragment }> }> }
  )> };

export type PlayerQueryVariables = {
  id: Scalars['ID'];
};


export type PlayerQuery = { player?: Maybe<(
    Pick<Player, 'id' | 'name' | 'isConnected' | 'isArchived'>
    & { team: Pick<PublicTeam, 'id' | 'name' | 'isOwner' | 'isCaptain'>, user?: Maybe<(
      Pick<User, 'id' | 'name' | 'isCurrentUser' | 'isPremium'>
      & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
    )> }
  )> };

export type PlayerStatsFragmentFragment = { matchesPlayed?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, matchesWon?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, matchesDrawn?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, matchesLost?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, winRate?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, assists?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, goalsScored?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, goalsPerGame?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, goalsFor?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, goalsAgainst?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, averageGoalsFor?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, averageGoalsAgainst?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, cleanSheets?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, cleanSheetsRate?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, dotdVotesReceived?: Maybe<Pick<PlayerStat, 'score' | 'rank'>>, motmVotesReceived?: Maybe<Pick<PlayerStat, 'score' | 'rank'>> };

export type PlayerStatsQueryVariables = {
  id: Scalars['ID'];
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
};


export type PlayerStatsQuery = { player?: Maybe<(
    Pick<Player, 'id' | 'name'>
    & { stats?: Maybe<PlayerStatsFragmentFragment> }
  )> };

export type PlayerVoteListEdgeFragment = { node: (
    Pick<Vote, 'id' | 'comment'>
    & { voteType: VoteTypeFragmentFragment, match: Pick<Match, 'id' | 'opponent' | 'playedAt'> }
  ) };

export type PlayerVotesQueryVariables = {
  id: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PlayerVotesQuery = { player?: Maybe<{ votes?: Maybe<{ pageInfo: Pick<PageInfo, 'endCursor' | 'hasNextPage'>, edges: Array<PlayerVoteListEdgeFragment> }> }> };

export type ReferralCodeQueryVariables = {
  code: Scalars['String'];
};


export type ReferralCodeQuery = { referralCode?: Maybe<(
    Pick<ReferralCode, 'id' | 'code'>
    & { user: (
      Pick<User, 'id' | 'name'>
      & { profileImage?: Maybe<Pick<Image, 'id' | 'url' | 'caption'>> }
    ) }
  )> };

export type CreateCheckoutSessionMutationVariables = {
  input: CreateSessionInput;
};


export type CreateCheckoutSessionMutation = { createCheckoutSession?: Maybe<{ session?: Maybe<Pick<CheckoutSession, 'id'>>, error?: Maybe<Pick<Error, 'message'>> }> };

export type CreateBillingPortalSessionMutationVariables = {
  input: CreatePortalSessionInput;
};


export type CreateBillingPortalSessionMutation = { createPortalSession?: Maybe<Pick<CreatePortalSessionResponse, 'url'>> };

export type PlansQueryVariables = {};


export type PlansQuery = { plans?: Maybe<Array<Pick<Plan, 'id' | 'nickname' | 'active' | 'amount' | 'interval' | 'keyPoints'>>> };

export type CreateKitItemForTeamMutationVariables = {
  teamId: Scalars['ID'];
  input: CreateKitItemInput;
};


export type CreateKitItemForTeamMutation = { createKitItemForTeam?: Maybe<{ entity?: Maybe<(
      Pick<KitItem, 'id' | 'name'>
      & { team: Pick<Team, 'id'> }
    )>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type DeleteKitItemMutationVariables = {
  id: Scalars['UUID'];
};


export type DeleteKitItemMutation = { deleteKitItem?: Maybe<(
    Pick<DeleteKitItemResponse, 'error'>
    & { team?: Maybe<Pick<Team, 'id'>> }
  )> };

export type GetKitItemQueryVariables = {
  id: Scalars['UUID'];
};


export type GetKitItemQuery = { kitItem?: Maybe<KitItemFragmentFragment> };

export type TeamKitItemsQueryVariables = {
  id: Scalars['ID'];
};


export type TeamKitItemsQuery = { team?: Maybe<(
    Pick<Team, 'id' | 'name'>
    & { kitItems: Array<Pick<KitItem, 'id' | 'name'>> }
  )> };

export type UpdateKitItemMutationVariables = {
  id: Scalars['UUID'];
  input: UpdateKitItemInput;
};


export type UpdateKitItemMutation = { updateKitItem?: Maybe<{ entity?: Maybe<(
      Pick<KitItem, 'id' | 'name'>
      & { team: Pick<Team, 'id'> }
    )>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type AssistsLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type AssistsLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ assists: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type AverageGoalsAgainstLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type AverageGoalsAgainstLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ averageGoalsAgainst: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type AverageGoalsForLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type AverageGoalsForLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ averageGoalsFor: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type CleanSheetsRateLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type CleanSheetsRateLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ cleanSheetsRate: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type CleanSheetsLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type CleanSheetsLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ cleanSheets: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type GoalsAgainstLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type GoalsAgainstLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ goalsAgainst: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type GoalsForLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type GoalsForLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ goalsFor: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type GoalsLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type GoalsLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ goals: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type GoalsPerGameLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type GoalsPerGameLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ goalsPerGame: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type MatchesDrawnLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type MatchesDrawnLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ matchesDrawn: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type MatchesLostLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type MatchesLostLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ matchesLost: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type MatchesPlayedLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type MatchesPlayedLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ matchesPlayed: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type MatchesWonLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type MatchesWonLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ matchesWon: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type VotesLeaderboardQueryVariables = {
  id: Scalars['ID'];
  voteType: Scalars['String'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type VotesLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ votes: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type WinRateLeaderboardQueryVariables = {
  id: Scalars['ID'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type WinRateLeaderboardQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { leaderboards?: Maybe<{ winRate: Array<LeaderboardItemFragmentFragment> }> }
  )> };

export type CreateTeamMutationVariables = {
  input: CreateTeamInput;
};


export type CreateTeamMutation = { createTeam?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<Team, 'id' | 'name' | 'location' | 'description'>> }> };

export type ApproveTeamRequestMutationVariables = {
  playerId?: Maybe<Scalars['ID']>;
  requestId: Scalars['ID'];
  playerName?: Maybe<Scalars['String']>;
};


export type ApproveTeamRequestMutation = { approveTeamRequest?: Maybe<{ entity?: Maybe<Pick<Player, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type DeclineTeamRequestMutationVariables = {
  requestId: Scalars['ID'];
};


export type DeclineTeamRequestMutation = { declineTeamRequest?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type CreateClubMutationVariables = {
  input: CreateClubInput;
};


export type CreateClubMutation = { createClub?: Maybe<{ entity?: Maybe<(
      Pick<Club, 'id' | 'name'>
      & { teams: Array<Pick<Team, 'id' | 'name'>> }
    )>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type CreateClubRequestMutationVariables = {
  input: CreateClubRequestInput;
};


export type CreateClubRequestMutation = { createClubRequest?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<ClubRequest, 'id'>> }> };

export type SearchClubsQueryVariables = {
  query: Scalars['String'];
};


export type SearchClubsQuery = { searchClubs: Array<Pick<Club, 'id' | 'name' | 'location'>> };

export type AssignPlayerToUserMutationVariables = {
  input: AssignPlayerToUserInput;
};


export type AssignPlayerToUserMutation = { assignPlayerToUser?: Maybe<{ entity?: Maybe<(
      Pick<Player, 'id' | 'name'>
      & { user?: Maybe<Pick<User, 'id' | 'name'>> }
    )>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type CoCaptainsQueryVariables = {
  teamId: Scalars['ID'];
};


export type CoCaptainsQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { coCaptains?: Maybe<Array<(
      Pick<User, 'id' | 'name'>
      & { profileImage?: Maybe<Pick<Image, 'url' | 'caption'>> }
    )>> }
  )> };

export type CreatePlayerMutationVariables = {
  name: Scalars['String'];
  teamId: Scalars['ID'];
};


export type CreatePlayerMutation = { createPlayer?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<(
      Pick<Player, 'id' | 'name'>
      & { team: (
        Pick<PublicTeam, 'id' | 'name'>
        & { players: Array<Pick<Player, 'id' | 'name'>> }
      ) }
    )> }> };

export type DeletePlayerMutationVariables = {
  id: Scalars['ID'];
};


export type DeletePlayerMutation = { deletePlayer?: Maybe<(
    Pick<DeletePlayerResponse, 'error'>
    & { team?: Maybe<Pick<Team, 'id'>> }
  )> };

export type MergePlayersMutationVariables = {
  source: Scalars['ID'];
  target: Scalars['ID'];
};


export type MergePlayersMutation = { mergePlayers?: Maybe<Pick<Player, 'id' | 'name'>> };

export type SetTeamCoCaptainsMutationVariables = {
  input: SetTeamCoCaptainsInput;
};


export type SetTeamCoCaptainsMutation = { setTeamCoCaptains?: Maybe<{ entity?: Maybe<(
      Pick<Team, 'id'>
      & { coCaptains?: Maybe<Array<Pick<User, 'id' | 'name'>>> }
    )> }> };

export type TeamPlayersQueryVariables = {
  id: Scalars['ID'];
};


export type TeamPlayersQuery = { team?: Maybe<(
    Pick<Team, 'id' | 'name'>
    & { players: Array<PlayerFragmentFragment> }
  )> };

export type UpdatePlayerMutationVariables = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};


export type UpdatePlayerMutation = { updatePlayer?: Maybe<{ entity?: Maybe<Pick<Player, 'id' | 'name'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type CreateSeasonMutationVariables = {
  input: CreateSeasonInput;
};


export type CreateSeasonMutation = { createSeason?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<Season, 'id' | 'name' | 'startedAt' | 'endedAt'>> }> };

export type DeleteSeasonMutationVariables = {
  id: Scalars['ID'];
};


export type DeleteSeasonMutation = { deleteSeason?: Maybe<{ team?: Maybe<(
      Pick<Team, 'id'>
      & { seasons: Array<Pick<Season, 'id' | 'name'>> }
    )> }> };

export type GetSeasonQueryVariables = {
  id: Scalars['ID'];
};


export type GetSeasonQuery = { season?: Maybe<Pick<Season, 'id' | 'name' | 'startedAt' | 'endedAt'>> };

export type TeamSeasonsQueryVariables = {
  id: Scalars['ID'];
};


export type TeamSeasonsQuery = { team?: Maybe<(
    Pick<Team, 'id'>
    & { seasons: Array<Pick<Season, 'id' | 'name' | 'startedAt' | 'endedAt'>> }
  )> };

export type UpdateSeasonMutationVariables = {
  id: Scalars['ID'];
  input: UpdateSeasonInput;
};


export type UpdateSeasonMutation = { updateSeason?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<Season, 'id' | 'name' | 'startedAt' | 'endedAt'>> }> };

export type UpdateTeamMutationVariables = {
  id: Scalars['ID'];
  input: UpdateTeamInput;
};


export type UpdateTeamMutation = { updateTeam?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<Team, 'id' | 'name' | 'location' | 'description' | 'paymentLink' | 'leagueLink'>> }> };

export type UpdateVoteTypeMutationVariables = {
  id: Scalars['ID'];
  input: UpdateVoteTypeInput;
};


export type UpdateVoteTypeMutation = { updateVoteType?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<Pick<VoteType, 'id' | 'name' | 'position' | 'enabled'>> }> };

export type CreatePushSubscriptionMutationVariables = {
  input: CreatePushSubscriptionInput;
};


export type CreatePushSubscriptionMutation = { createPushSubscription?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type DeletePushSubscriptionMutationVariables = {
  id: Scalars['ID'];
};


export type DeletePushSubscriptionMutation = { deletePushSubscription?: Maybe<{ user?: Maybe<Pick<User, 'id'>> }> };

export type PushSubscriptionsQueryVariables = {};


export type PushSubscriptionsQuery = { user?: Maybe<(
    Pick<User, 'id'>
    & { pushSubscriptions?: Maybe<Array<Pick<PushSubscription, 'id' | 'endpoint'>>> }
  )> };

export type SendTestMessageMutationVariables = {
  input: TestPushMessageInput;
};


export type SendTestMessageMutation = Pick<RootMutationType, 'sendTestPushMessage'>;

export type DisableTotpMutationVariables = {};


export type DisableTotpMutation = { disableTotp?: Maybe<{ entity?: Maybe<Pick<User, 'id'>> }> };

export type EnableTotpMutationVariables = {
  input: EnableTotpInput;
};


export type EnableTotpMutation = { enableTotp?: Maybe<{ entity?: Maybe<Pick<User, 'id'>> }> };

export type NotificationPreferencesQueryVariables = {};


export type NotificationPreferencesQuery = { notifications: Array<Pick<Notification, 'id' | 'name' | 'slug' | 'description' | 'enabled'>> };

export type SetNotificationPreferenceMutationVariables = {
  notificationId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};


export type SetNotificationPreferenceMutation = { setNotificationPreference: { notifications: Array<Pick<Notification, 'id' | 'name' | 'slug' | 'enabled'>> } };

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};


export type UpdateUserMutation = { updateUser?: Maybe<{ entity?: Maybe<Pick<User, 'id' | 'name' | 'email'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type CloseMatchVotingMutationVariables = {
  id: Scalars['UUID'];
};


export type CloseMatchVotingMutation = { closeMatchVoting?: Maybe<{ entity?: Maybe<Pick<Match, 'id'>>, validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type CreateVoteMutationVariables = {
  input: CreateVoteInput;
};


export type CreateVoteMutation = { createVote?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>>, entity?: Maybe<{ match: Pick<Match, 'id'> }> }> };

export type MatchVoteCountsQueryVariables = {
  matchId: Scalars['UUID'];
  voteTypeId?: Maybe<Scalars['ID']>;
};


export type MatchVoteCountsQuery = { match?: Maybe<(
    Pick<Match, 'id'>
    & { voteResult?: Maybe<{ counts: Array<PlayerVoteCountFragmentFragment> }> }
  )> };

export type MatchVotesQueryVariables = {
  matchId: Scalars['UUID'];
};


export type MatchVotesQuery = { match?: Maybe<(
    Pick<Match, 'id'>
    & { voteResult?: Maybe<{ votes?: Maybe<Array<VoteFragmentFragment>> }> }
  )> };

export type VotesCastQueryVariables = {
  matchId: Scalars['UUID'];
};


export type VotesCastQuery = { match?: Maybe<Pick<Match, 'id' | 'voteTypesCast'>> };

export type ConfirmEmailAddressMutationVariables = {
  token: Scalars['String'];
};


export type ConfirmEmailAddressMutation = { confirmEmailAddress?: Maybe<{ validation?: Maybe<Array<Pick<Validation, 'key' | 'reason'>>> }> };

export type RefreshSessionMutationVariables = {};


export type RefreshSessionMutation = { refreshSession?: Maybe<{ entity?: Maybe<{ user: Pick<User, 'name'> }> }> };

export const FullUserFragmentDoc = gql`
    fragment FullUser on User {
  id
  email
  name
  referralCode
  subscriptionStatus
  isPremium
  billingId
  emailConfirmed
  totpEnabled
  profileImage {
    url
    caption
  }
  settings {
    hideClub
    statsDate
    onboardedTeams
    dismissedAlerts
  }
  fitnessDeviceUsers {
    id
  }
  teams {
    id
    name
    isOwner
  }
  experiments {
    id
    name
  }
  teamRequests {
    id
    team {
      id
      name
    }
  }
}
    `;
export const TeamRequestFragmentFragmentDoc = gql`
    fragment TeamRequestFragment on TeamRequest {
  id
  requestedAt
  user {
    id
    name
    email
  }
}
    `;
export const VoteTypeFragmentFragmentDoc = gql`
    fragment VoteTypeFragment on VoteType {
  id
  name
  enabled
  emoji
  position
}
    `;
export const PlayerUserFragmentFragmentDoc = gql`
    fragment PlayerUserFragment on User {
  id
  name
  isCurrentUser
  isPremium
  profileImage {
    url
    caption
  }
}
    `;
export const PlayerFragmentFragmentDoc = gql`
    fragment PlayerFragment on Player {
  id
  name
  isArchived
  user {
    ...PlayerUserFragment
  }
  team {
    id
    name
  }
}
    ${PlayerUserFragmentFragmentDoc}`;
export const TeamFragmentFragmentDoc = gql`
    fragment TeamFragment on Team {
  id
  name
  location
  description
  createdAt
  isOwner
  players {
    ...PlayerFragment
  }
  kitItems {
    id
    name
  }
  paymentLink
  leagueLink
}
    ${PlayerFragmentFragmentDoc}`;
export const FullTeamFragmentDoc = gql`
    fragment FullTeam on Team {
  isOwner
  isCaptain
  player {
    id
    name
    user {
      id
      name
      isCurrentUser
      profileImage {
        url
        caption
      }
    }
  }
  requests {
    ...TeamRequestFragment
  }
  club {
    id
    name
  }
  voteTypes {
    ...VoteTypeFragment
  }
  stats {
    matchesPlayed
  }
  ...TeamFragment
}
    ${TeamRequestFragmentFragmentDoc}
${VoteTypeFragmentFragmentDoc}
${TeamFragmentFragmentDoc}`;
export const CardRecipientFragmentFragmentDoc = gql`
    fragment CardRecipientFragment on CardRecipient {
  id
  cardType
  player {
    id
    name
  }
}
    `;
export const LeaderboardItemFragmentFragmentDoc = gql`
    fragment LeaderboardItemFragment on LeaderboardItem {
  score
  rank
  player {
    id
    name
    isArchived
    user {
      ...PlayerUserFragment
    }
  }
}
    ${PlayerUserFragmentFragmentDoc}`;
export const KitItemFragmentFragmentDoc = gql`
    fragment KitItemFragment on KitItem {
  id
  name
  team {
    id
  }
}
    `;
export const VoteFragmentFragmentDoc = gql`
    fragment VoteFragment on Vote {
  id
  comment
  commentHtml
  player {
    ...PlayerFragment
  }
  voteType {
    ...VoteTypeFragment
  }
}
    ${PlayerFragmentFragmentDoc}
${VoteTypeFragmentFragmentDoc}`;
export const ScorerFragmentFragmentDoc = gql`
    fragment ScorerFragment on Scorer {
  id
  player {
    id
    name
  }
}
    `;
export const MatchListItemFragmentDoc = gql`
    fragment MatchListItem on Match {
  id
  opponent
  playedAt
  meetAt
  votingStatus
  atHome
  excludedFromStats
  timeZone
  location {
    address
    lat
    lng
  }
  image {
    id
    url
    caption
  }
  goalsAgainst
  goalsFor
  availability
  team {
    name
    id
  }
  scorers {
    ...ScorerFragment
  }
}
    ${ScorerFragmentFragmentDoc}`;
export const MatchListEdgeFragmentDoc = gql`
    fragment MatchListEdge on MatchEdge {
  cursor
  node {
    ...MatchListItem
  }
}
    ${MatchListItemFragmentDoc}`;
export const PlayerVoteCountFragmentFragmentDoc = gql`
    fragment PlayerVoteCountFragment on PlayerVoteCount {
  voteType {
    ...VoteTypeFragment
  }
  voteCount
  player {
    id
    name
    isArchived
    user {
      ...PlayerUserFragment
    }
  }
}
    ${VoteTypeFragmentFragmentDoc}
${PlayerUserFragmentFragmentDoc}`;
export const MatchHighlightItemFragmentDoc = gql`
    fragment MatchHighlightItem on Match {
  id
  opponent
  playedAt
  images {
    id
    url
    caption
  }
  team {
    id
    name
    isOwner
    isCaptain
    player {
      id
    }
  }
  matchReport
  voteResult {
    winners {
      ...PlayerVoteCountFragment
    }
  }
}
    ${PlayerVoteCountFragmentFragmentDoc}`;
export const ClubPlayerAvailabilitiesFragmentDoc = gql`
    fragment ClubPlayerAvailabilities on Availability {
  id
  status
  player {
    id
    name
    isArchived
    user {
      id
      profileImage {
        url
        caption
      }
    }
  }
}
    `;
export const FullClubFragmentDoc = gql`
    fragment FullClub on Club {
  id
  name
  location
  teams {
    id
    name
    isOwner
  }
  clubRequests {
    id
    requestedAt
    team {
      id
      name
      location
    }
  }
}
    `;
export const TeamResultFragmentDoc = gql`
    fragment TeamResult on PublicTeam {
  name
  id
  location
  description
  user {
    name
  }
}
    `;
export const MatchEventFragmentFragmentDoc = gql`
    fragment MatchEventFragment on MatchEvent {
  id
  event
  player {
    id
    name
    isArchived
    user {
      ...PlayerUserFragment
    }
  }
}
    ${PlayerUserFragmentFragmentDoc}`;
export const AvailabilityResponseEntityFragmentDoc = gql`
    fragment AvailabilityResponseEntity on Availability {
  id
  status
  player {
    id
    name
  }
  match {
    id
  }
}
    `;
export const MatchPlayerFragmentDoc = gql`
    fragment MatchPlayer on Player {
  id
  name
  isArchived
  user {
    id
    name
    isCurrentUser
    isPremium
    profileImage {
      url
      caption
    }
  }
}
    `;
export const FullMatchFragmentDoc = gql`
    fragment FullMatch on Match {
  id
  opponent
  meetAt
  playedAt
  votingStatus
  atHome
  timeZone
  location {
    address
    lat
    lng
  }
  image {
    id
    url
    caption
  }
  goalsAgainst
  goalsFor
  voteCount
  excludedFromStats
  availability
  matchReport
  matchReportHtml
  team {
    isOwner
    isCaptain
    id
    name
    players {
      ...PlayerFragment
    }
    player {
      id
      name
      user {
        id
        isCurrentUser
        profileImage {
          url
          caption
        }
      }
    }
    voteTypes {
      ...VoteTypeFragment
    }
    paymentLink
    leagueLink
  }
  players {
    ...MatchPlayer
  }
  availabilities {
    status
    player {
      id
      name
    }
  }
  kitItems {
    id
    name
  }
}
    ${PlayerFragmentFragmentDoc}
${VoteTypeFragmentFragmentDoc}
${MatchPlayerFragmentDoc}`;
export const PlayerMatchEventFragmentDoc = gql`
    fragment PlayerMatchEvent on PlayerEvent {
  event
  eventType
  id
  count
  match {
    id
    opponent
    playedAt
  }
}
    `;
export const PlayerStatsFragmentFragmentDoc = gql`
    fragment PlayerStatsFragment on PlayerStats {
  matchesPlayed {
    score
    rank
  }
  matchesWon {
    score
    rank
  }
  matchesDrawn {
    score
    rank
  }
  matchesLost {
    score
    rank
  }
  winRate {
    score
    rank
  }
  assists {
    score
    rank
  }
  goalsScored {
    score
    rank
  }
  goalsPerGame {
    score
    rank
  }
  goalsFor {
    score
    rank
  }
  goalsAgainst {
    score
    rank
  }
  averageGoalsFor {
    score
    rank
  }
  averageGoalsAgainst {
    score
    rank
  }
  cleanSheets {
    score
    rank
  }
  cleanSheetsRate {
    score
    rank
  }
  dotdVotesReceived: votesReceived(type: "dotd") {
    score
    rank
  }
  motmVotesReceived: votesReceived(type: "motm") {
    score
    rank
  }
}
    `;
export const PlayerVoteListEdgeFragmentDoc = gql`
    fragment PlayerVoteListEdge on VoteEdge {
  node {
    id
    comment
    voteType {
      ...VoteTypeFragment
    }
    match {
      id
      opponent
      playedAt
    }
  }
}
    ${VoteTypeFragmentFragmentDoc}`;
export const RequestEmailAddressConfirmationEmailDocument = gql`
    mutation RequestEmailAddressConfirmationEmail {
  requestEmailConfirmationEmail
}
    `;

export function useRequestEmailAddressConfirmationEmailMutation() {
  return Urql.useMutation<RequestEmailAddressConfirmationEmailMutation, RequestEmailAddressConfirmationEmailMutationVariables>(RequestEmailAddressConfirmationEmailDocument);
};
export const UserDocument = gql`
    query User {
  user {
    ...FullUser
  }
}
    ${FullUserFragmentDoc}`;

export function useUserQuery(options: Omit<Urql.UseQueryArgs<UserQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserQuery>({ query: UserDocument, ...options });
};
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    result
  }
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const SetDefaultTeamDocument = gql`
    mutation SetDefaultTeam($teamId: Int!) {
  updateUser(input: {settings: {defaultTeamId: $teamId}}) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useSetDefaultTeamMutation() {
  return Urql.useMutation<SetDefaultTeamMutation, SetDefaultTeamMutationVariables>(SetDefaultTeamDocument);
};
export const TeamDocument = gql`
    query Team($id: ID!) {
  team(id: $id) {
    ...FullTeam
  }
}
    ${FullTeamFragmentDoc}`;

export function useTeamQuery(options: Omit<Urql.UseQueryArgs<TeamQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamQuery>({ query: TeamDocument, ...options });
};
export const UserPlayersDocument = gql`
    query UserPlayers($first: Int!, $after: String, $query: String) {
  connectedPlayers(first: $first, after: $after, query: $query) {
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...PlayerFragment
      }
    }
  }
}
    ${PlayerFragmentFragmentDoc}`;

export function useUserPlayersQuery(options: Omit<Urql.UseQueryArgs<UserPlayersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserPlayersQuery>({ query: UserPlayersDocument, ...options });
};
export const DeleteImageDocument = gql`
    mutation DeleteImage($id: ID!) {
  deleteImage(id: $id) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useDeleteImageMutation() {
  return Urql.useMutation<DeleteImageMutation, DeleteImageMutationVariables>(DeleteImageDocument);
};
export const DetachPlayerFromUserDocument = gql`
    mutation DetachPlayerFromUser($input: DetachPlayerFromUserInput!) {
  detachPlayerFromUser(input: $input) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useDetachPlayerFromUserMutation() {
  return Urql.useMutation<DetachPlayerFromUserMutation, DetachPlayerFromUserMutationVariables>(DetachPlayerFromUserDocument);
};
export const MatchListDocument = gql`
    query MatchList($id: ID!, $direction: SortDirection, $playedAfter: DateTime, $playedBefore: DateTime, $before: String, $after: String, $first: Int, $last: Int, $ignoreStatsExcludedMatches: Boolean) {
  team(id: $id) {
    id
    matches(direction: $direction, playedAfter: $playedAfter, playedBefore: $playedBefore, before: $before, after: $after, first: $first, last: $last, ignoreStatsExcludedMatches: $ignoreStatsExcludedMatches) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        ...MatchListEdge
      }
    }
  }
}
    ${MatchListEdgeFragmentDoc}`;

export function useMatchListQuery(options: Omit<Urql.UseQueryArgs<MatchListQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchListQuery>({ query: MatchListDocument, ...options });
};
export const MatchActivityDocument = gql`
    query MatchActivity($id: ID!) {
  matchActivity(id: $id) {
    id
    match {
      id
      opponent
    }
    player {
      id
      name
      isArchived
      user {
        ...PlayerUserFragment
      }
    }
    positions {
      t
      lat
      lng
    }
    summaryData {
      calories
      device
      distance
      steps
      avgSpeed
      avgHr
      maxSpeed
    }
  }
}
    ${PlayerUserFragmentFragmentDoc}`;

export function useMatchActivityQuery(options: Omit<Urql.UseQueryArgs<MatchActivityQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchActivityQuery>({ query: MatchActivityDocument, ...options });
};
export const CreateAccountDocument = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
  createAccount(input: $input) {
    validation {
      key
      reason
    }
    entity {
      user {
        name
        id
      }
    }
  }
}
    `;

export function useCreateAccountMutation() {
  return Urql.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument);
};
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    error {
      __typename
      errorType
      ... on InvalidCredentialsLoginError {
        errorType
      }
      ... on MfaNeededLoginError {
        errorType
        mfaToken
      }
    }
    session {
      user {
        id
        name
      }
    }
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const GetTotpSecretDocument = gql`
    query GetTotpSecret {
  totpSecret {
    uri
    secret
  }
}
    `;

export function useGetTotpSecretQuery(options: Omit<Urql.UseQueryArgs<GetTotpSecretQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetTotpSecretQuery>({ query: GetTotpSecretDocument, ...options });
};
export const VerifyMfaDocument = gql`
    mutation VerifyMfa($input: VerifyMfaInput!) {
  verifyMfa(input: $input) {
    error {
      __typename
      errorType
    }
    session {
      user {
        id
      }
      accessToken
    }
  }
}
    `;

export function useVerifyMfaMutation() {
  return Urql.useMutation<VerifyMfaMutation, VerifyMfaMutationVariables>(VerifyMfaDocument);
};
export const ClubMatchHighlightsDocument = gql`
    query ClubMatchHighlights($clubId: ID!, $direction: SortDirection, $playedAfter: DateTime, $playedBefore: DateTime, $before: String, $after: String, $first: Int, $last: Int, $filter: ClubMatchFilterType) {
  club(id: $clubId) {
    id
    matches(direction: $direction, playedAfter: $playedAfter, playedBefore: $playedBefore, before: $before, after: $after, first: $first, last: $last, filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...MatchHighlightItem
        }
      }
    }
  }
}
    ${MatchHighlightItemFragmentDoc}`;

export function useClubMatchHighlightsQuery(options: Omit<Urql.UseQueryArgs<ClubMatchHighlightsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClubMatchHighlightsQuery>({ query: ClubMatchHighlightsDocument, ...options });
};
export const ApproveClubRequestDocument = gql`
    mutation ApproveClubRequest($id: ID!) {
  approveClubRequest(id: $id) {
    entity {
      id
      name
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useApproveClubRequestMutation() {
  return Urql.useMutation<ApproveClubRequestMutation, ApproveClubRequestMutationVariables>(ApproveClubRequestDocument);
};
export const ClubAvailabilitiesDocument = gql`
    query ClubAvailabilities($clubId: ID!, $direction: SortDirection, $playedAfter: DateTime, $playedBefore: DateTime, $before: String, $after: String, $first: Int, $last: Int, $filter: ClubMatchFilterType) {
  club(id: $clubId) {
    id
    matches(direction: $direction, playedAfter: $playedAfter, playedBefore: $playedBefore, before: $before, after: $after, first: $first, last: $last, filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          opponent
          atHome
          playedAt
          timeZone
          team {
            id
            name
          }
          availabilities {
            ...ClubPlayerAvailabilities
          }
        }
      }
    }
  }
}
    ${ClubPlayerAvailabilitiesFragmentDoc}`;

export function useClubAvailabilitiesQuery(options: Omit<Urql.UseQueryArgs<ClubAvailabilitiesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClubAvailabilitiesQuery>({ query: ClubAvailabilitiesDocument, ...options });
};
export const ClubMatchesDocument = gql`
    query ClubMatches($clubId: ID!, $direction: SortDirection, $playedAfter: DateTime, $playedBefore: DateTime, $before: String, $after: String, $first: Int, $last: Int, $filter: ClubMatchFilterType) {
  club(id: $clubId) {
    id
    matches(direction: $direction, playedAfter: $playedAfter, playedBefore: $playedBefore, before: $before, after: $after, first: $first, last: $last, filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        ...MatchListEdge
      }
    }
  }
}
    ${MatchListEdgeFragmentDoc}`;

export function useClubMatchesQuery(options: Omit<Urql.UseQueryArgs<ClubMatchesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClubMatchesQuery>({ query: ClubMatchesDocument, ...options });
};
export const ClubDocument = gql`
    query Club($id: ID!) {
  club(id: $id) {
    ...FullClub
  }
}
    ${FullClubFragmentDoc}`;

export function useClubQuery(options: Omit<Urql.UseQueryArgs<ClubQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ClubQuery>({ query: ClubDocument, ...options });
};
export const DeclineClubRequestDocument = gql`
    mutation DeclineClubRequest($id: ID!) {
  declineClubRequest(id: $id) {
    entity {
      id
      name
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useDeclineClubRequestMutation() {
  return Urql.useMutation<DeclineClubRequestMutation, DeclineClubRequestMutationVariables>(DeclineClubRequestDocument);
};
export const DeleteFitnessDeviceDocument = gql`
    mutation DeleteFitnessDevice($id: ID!) {
  deleteFitnessDeviceUser(id: $id) {
    id
    fitnessDeviceUsers {
      id
      provider
    }
  }
}
    `;

export function useDeleteFitnessDeviceMutation() {
  return Urql.useMutation<DeleteFitnessDeviceMutation, DeleteFitnessDeviceMutationVariables>(DeleteFitnessDeviceDocument);
};
export const GetTerraSessionUrlDocument = gql`
    mutation GetTerraSessionUrl($input: FitnessDataAuthUrlInput!) {
  getFitnessDataAuthUrl(input: $input) {
    sessionUrl
  }
}
    `;

export function useGetTerraSessionUrlMutation() {
  return Urql.useMutation<GetTerraSessionUrlMutation, GetTerraSessionUrlMutationVariables>(GetTerraSessionUrlDocument);
};
export const UserFitnessDevicesDocument = gql`
    query UserFitnessDevices {
  user {
    id
    fitnessDeviceUsers {
      id
      provider
    }
  }
}
    `;

export function useUserFitnessDevicesQuery(options: Omit<Urql.UseQueryArgs<UserFitnessDevicesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<UserFitnessDevicesQuery>({ query: UserFitnessDevicesDocument, ...options });
};
export const CreateTeamRequestDocument = gql`
    mutation CreateTeamRequest($teamId: ID!) {
  createTeamRequest(teamId: $teamId) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useCreateTeamRequestMutation() {
  return Urql.useMutation<CreateTeamRequestMutation, CreateTeamRequestMutationVariables>(CreateTeamRequestDocument);
};
export const SearchTeamsDocument = gql`
    query SearchTeams($query: String!) {
  searchTeams(query: $query) {
    ...TeamResult
  }
}
    ${TeamResultFragmentDoc}`;

export function useSearchTeamsQuery(options: Omit<Urql.UseQueryArgs<SearchTeamsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchTeamsQuery>({ query: SearchTeamsDocument, ...options });
};
export const TeamRequestsDocument = gql`
    query TeamRequests {
  teamRequests {
    id
    requestedAt
    team {
      id
      name
    }
  }
}
    `;

export function useTeamRequestsQuery(options: Omit<Urql.UseQueryArgs<TeamRequestsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamRequestsQuery>({ query: TeamRequestsDocument, ...options });
};
export const MatchVoteWinnersDocument = gql`
    query MatchVoteWinners($matchId: UUID!) {
  match(id: $matchId) {
    id
    voteResult {
      winners {
        ...PlayerVoteCountFragment
      }
    }
  }
}
    ${PlayerVoteCountFragmentFragmentDoc}`;

export function useMatchVoteWinnersQuery(options: Omit<Urql.UseQueryArgs<MatchVoteWinnersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchVoteWinnersQuery>({ query: MatchVoteWinnersDocument, ...options });
};
export const MatchActivitiesDocument = gql`
    query MatchActivities($matchId: UUID!) {
  match(id: $matchId) {
    opponent
    activities {
      id
      summaryData {
        distance
      }
      player {
        id
        name
        isArchived
        user {
          ...PlayerUserFragment
        }
      }
    }
  }
}
    ${PlayerUserFragmentFragmentDoc}`;

export function useMatchActivitiesQuery(options: Omit<Urql.UseQueryArgs<MatchActivitiesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchActivitiesQuery>({ query: MatchActivitiesDocument, ...options });
};
export const CreateMatchEventDocument = gql`
    mutation CreateMatchEvent($input: CreateMatchEventInput!) {
  createMatchEvent(input: $input) {
    entity {
      id
      player {
        id
        name
      }
      match {
        id
      }
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useCreateMatchEventMutation() {
  return Urql.useMutation<CreateMatchEventMutation, CreateMatchEventMutationVariables>(CreateMatchEventDocument);
};
export const DeleteMatchEventDocument = gql`
    mutation DeleteMatchEvent($id: ID!) {
  deleteMatchEvent(id: $id) {
    error
    match {
      scorers {
        id
      }
    }
  }
}
    `;

export function useDeleteMatchEventMutation() {
  return Urql.useMutation<DeleteMatchEventMutation, DeleteMatchEventMutationVariables>(DeleteMatchEventDocument);
};
export const MatchStatsDocument = gql`
    query MatchStats($id: UUID!) {
  match(id: $id) {
    events {
      ...MatchEventFragment
    }
  }
}
    ${MatchEventFragmentFragmentDoc}`;

export function useMatchStatsQuery(options: Omit<Urql.UseQueryArgs<MatchStatsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchStatsQuery>({ query: MatchStatsDocument, ...options });
};
export const AttachMatchImageDocument = gql`
    mutation AttachMatchImage($id: UUID!, $image: ImageInput!) {
  attachMatchImage(id: $id, image: $image) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useAttachMatchImageMutation() {
  return Urql.useMutation<AttachMatchImageMutation, AttachMatchImageMutationVariables>(AttachMatchImageDocument);
};
export const SetMatchAvailabilityDocument = gql`
    mutation SetMatchAvailability($input: SetMatchAvailabilityInput!) {
  setMatchAvailability(input: $input) {
    entity {
      ...AvailabilityResponseEntity
    }
    validation {
      reason
      key
    }
  }
}
    ${AvailabilityResponseEntityFragmentDoc}`;

export function useSetMatchAvailabilityMutation() {
  return Urql.useMutation<SetMatchAvailabilityMutation, SetMatchAvailabilityMutationVariables>(SetMatchAvailabilityDocument);
};
export const CreateMatchDocument = gql`
    mutation CreateMatch($input: CreateMatchInput!) {
  createMatch(input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      opponent
      playedAt
    }
  }
}
    `;

export function useCreateMatchMutation() {
  return Urql.useMutation<CreateMatchMutation, CreateMatchMutationVariables>(CreateMatchDocument);
};
export const DeleteMatchDocument = gql`
    mutation DeleteMatch($id: UUID!) {
  deleteMatch(id: $id) {
    entity {
      id
    }
  }
}
    `;

export function useDeleteMatchMutation() {
  return Urql.useMutation<DeleteMatchMutation, DeleteMatchMutationVariables>(DeleteMatchDocument);
};
export const GetUploadUrlDocument = gql`
    mutation GetUploadUrl($input: GetUploadUrlInput!) {
  getUploadUrl(input: $input) {
    path
    uploadUrl
  }
}
    `;

export function useGetUploadUrlMutation() {
  return Urql.useMutation<GetUploadUrlMutation, GetUploadUrlMutationVariables>(GetUploadUrlDocument);
};
export const MatchImagesDocument = gql`
    query MatchImages($id: UUID!) {
  match(id: $id) {
    id
    images {
      id
      url
    }
  }
}
    `;

export function useMatchImagesQuery(options: Omit<Urql.UseQueryArgs<MatchImagesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchImagesQuery>({ query: MatchImagesDocument, ...options });
};
export const MatchDocument = gql`
    query Match($id: UUID!) {
  match(id: $id) {
    ...FullMatch
  }
}
    ${FullMatchFragmentDoc}`;

export function useMatchQuery(options: Omit<Urql.UseQueryArgs<MatchQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchQuery>({ query: MatchDocument, ...options });
};
export const PickPlayersDocument = gql`
    mutation PickPlayers($input: PickPlayersInput!) {
  pickPlayers(input: $input) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function usePickPlayersMutation() {
  return Urql.useMutation<PickPlayersMutation, PickPlayersMutationVariables>(PickPlayersDocument);
};
export const RecentLocationsDocument = gql`
    query RecentLocations($teamId: ID!) {
  team(id: $teamId) {
    recentLocations {
      id
      address
      lat
      lng
    }
  }
}
    `;

export function useRecentLocationsQuery(options: Omit<Urql.UseQueryArgs<RecentLocationsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<RecentLocationsQuery>({ query: RecentLocationsDocument, ...options });
};
export const SetKitDocument = gql`
    mutation SetKit($input: SetKitInput!) {
  setKit(input: $input) {
    entity {
      id
      kitItems {
        id
        name
      }
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useSetKitMutation() {
  return Urql.useMutation<SetKitMutation, SetKitMutationVariables>(SetKitDocument);
};
export const SetMatchHighlightImageDocument = gql`
    mutation SetMatchHighlightImage($imageId: ID!) {
  setMatchHighlightImage(imageId: $imageId) {
    entity {
      id
      order
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useSetMatchHighlightImageMutation() {
  return Urql.useMutation<SetMatchHighlightImageMutation, SetMatchHighlightImageMutationVariables>(SetMatchHighlightImageDocument);
};
export const SetMatchLocationDocument = gql`
    mutation SetMatchLocation($matchId: UUID!, $location: LocationInput!) {
  setMatchLocation(matchId: $matchId, location: $location) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useSetMatchLocationMutation() {
  return Urql.useMutation<SetMatchLocationMutation, SetMatchLocationMutationVariables>(SetMatchLocationDocument);
};
export const UpdateMatchDocument = gql`
    mutation UpdateMatch($id: UUID!, $input: UpdateMatchInput!) {
  updateMatch(id: $id, input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      opponent
      playedAt
      atHome
      goalsAgainst
      goalsFor
    }
  }
}
    `;

export function useUpdateMatchMutation() {
  return Urql.useMutation<UpdateMatchMutation, UpdateMatchMutationVariables>(UpdateMatchDocument);
};
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useForgotPasswordMutation() {
  return Urql.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument);
};
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($password: String!, $token: String) {
  updatePassword(password: $password, token: $token) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument);
};
export const PlayerEventsDocument = gql`
    query PlayerEvents($id: ID!, $first: Int! = 6) {
  player(id: $id) {
    id
    events(first: $first) {
      edges {
        node {
          ...PlayerMatchEvent
        }
      }
    }
  }
}
    ${PlayerMatchEventFragmentDoc}`;

export function usePlayerEventsQuery(options: Omit<Urql.UseQueryArgs<PlayerEventsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PlayerEventsQuery>({ query: PlayerEventsDocument, ...options });
};
export const PlayerDocument = gql`
    query Player($id: ID!) {
  player(id: $id) {
    id
    name
    isConnected
    isArchived
    team {
      id
      name
      isOwner
      isCaptain
    }
    user {
      id
      name
      isCurrentUser
      isPremium
      profileImage {
        url
        caption
      }
    }
  }
}
    `;

export function usePlayerQuery(options: Omit<Urql.UseQueryArgs<PlayerQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PlayerQuery>({ query: PlayerDocument, ...options });
};
export const PlayerStatsDocument = gql`
    query PlayerStats($id: ID!, $from: DateTime!, $to: DateTime) {
  player(id: $id) {
    id
    name
    stats(from: $from, to: $to) {
      ...PlayerStatsFragment
    }
  }
}
    ${PlayerStatsFragmentFragmentDoc}`;

export function usePlayerStatsQuery(options: Omit<Urql.UseQueryArgs<PlayerStatsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PlayerStatsQuery>({ query: PlayerStatsDocument, ...options });
};
export const PlayerVotesDocument = gql`
    query PlayerVotes($id: ID!, $before: String, $after: String, $first: Int, $last: Int) {
  player(id: $id) {
    votes(before: $before, after: $after, first: $first, last: $last) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        ...PlayerVoteListEdge
      }
    }
  }
}
    ${PlayerVoteListEdgeFragmentDoc}`;

export function usePlayerVotesQuery(options: Omit<Urql.UseQueryArgs<PlayerVotesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PlayerVotesQuery>({ query: PlayerVotesDocument, ...options });
};
export const ReferralCodeDocument = gql`
    query ReferralCode($code: String!) {
  referralCode(code: $code) {
    id
    code
    user {
      id
      name
      profileImage {
        id
        url
        caption
      }
    }
  }
}
    `;

export function useReferralCodeQuery(options: Omit<Urql.UseQueryArgs<ReferralCodeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ReferralCodeQuery>({ query: ReferralCodeDocument, ...options });
};
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession($input: CreateSessionInput!) {
  createCheckoutSession(input: $input) {
    session {
      id
    }
    error {
      message
    }
  }
}
    `;

export function useCreateCheckoutSessionMutation() {
  return Urql.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument);
};
export const CreateBillingPortalSessionDocument = gql`
    mutation CreateBillingPortalSession($input: CreatePortalSessionInput!) {
  createPortalSession(input: $input) {
    url
  }
}
    `;

export function useCreateBillingPortalSessionMutation() {
  return Urql.useMutation<CreateBillingPortalSessionMutation, CreateBillingPortalSessionMutationVariables>(CreateBillingPortalSessionDocument);
};
export const PlansDocument = gql`
    query Plans {
  plans {
    id
    nickname
    active
    amount
    interval
    keyPoints
  }
}
    `;

export function usePlansQuery(options: Omit<Urql.UseQueryArgs<PlansQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PlansQuery>({ query: PlansDocument, ...options });
};
export const CreateKitItemForTeamDocument = gql`
    mutation CreateKitItemForTeam($teamId: ID!, $input: CreateKitItemInput!) {
  createKitItemForTeam(teamId: $teamId, input: $input) {
    entity {
      id
      name
      team {
        id
      }
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useCreateKitItemForTeamMutation() {
  return Urql.useMutation<CreateKitItemForTeamMutation, CreateKitItemForTeamMutationVariables>(CreateKitItemForTeamDocument);
};
export const DeleteKitItemDocument = gql`
    mutation DeleteKitItem($id: UUID!) {
  deleteKitItem(id: $id) {
    team {
      id
    }
    error
  }
}
    `;

export function useDeleteKitItemMutation() {
  return Urql.useMutation<DeleteKitItemMutation, DeleteKitItemMutationVariables>(DeleteKitItemDocument);
};
export const GetKitItemDocument = gql`
    query GetKitItem($id: UUID!) {
  kitItem(id: $id) {
    ...KitItemFragment
  }
}
    ${KitItemFragmentFragmentDoc}`;

export function useGetKitItemQuery(options: Omit<Urql.UseQueryArgs<GetKitItemQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetKitItemQuery>({ query: GetKitItemDocument, ...options });
};
export const TeamKitItemsDocument = gql`
    query TeamKitItems($id: ID!) {
  team(id: $id) {
    id
    name
    kitItems {
      id
      name
    }
  }
}
    `;

export function useTeamKitItemsQuery(options: Omit<Urql.UseQueryArgs<TeamKitItemsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamKitItemsQuery>({ query: TeamKitItemsDocument, ...options });
};
export const UpdateKitItemDocument = gql`
    mutation UpdateKitItem($id: UUID!, $input: UpdateKitItemInput!) {
  updateKitItem(id: $id, input: $input) {
    entity {
      id
      name
      team {
        id
      }
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useUpdateKitItemMutation() {
  return Urql.useMutation<UpdateKitItemMutation, UpdateKitItemMutationVariables>(UpdateKitItemDocument);
};
export const AssistsLeaderboardDocument = gql`
    query AssistsLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      assists(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useAssistsLeaderboardQuery(options: Omit<Urql.UseQueryArgs<AssistsLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AssistsLeaderboardQuery>({ query: AssistsLeaderboardDocument, ...options });
};
export const AverageGoalsAgainstLeaderboardDocument = gql`
    query AverageGoalsAgainstLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      averageGoalsAgainst(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useAverageGoalsAgainstLeaderboardQuery(options: Omit<Urql.UseQueryArgs<AverageGoalsAgainstLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AverageGoalsAgainstLeaderboardQuery>({ query: AverageGoalsAgainstLeaderboardDocument, ...options });
};
export const AverageGoalsForLeaderboardDocument = gql`
    query AverageGoalsForLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      averageGoalsFor(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useAverageGoalsForLeaderboardQuery(options: Omit<Urql.UseQueryArgs<AverageGoalsForLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AverageGoalsForLeaderboardQuery>({ query: AverageGoalsForLeaderboardDocument, ...options });
};
export const CleanSheetsRateLeaderboardDocument = gql`
    query CleanSheetsRateLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      cleanSheetsRate(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useCleanSheetsRateLeaderboardQuery(options: Omit<Urql.UseQueryArgs<CleanSheetsRateLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CleanSheetsRateLeaderboardQuery>({ query: CleanSheetsRateLeaderboardDocument, ...options });
};
export const CleanSheetsLeaderboardDocument = gql`
    query CleanSheetsLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      cleanSheets(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useCleanSheetsLeaderboardQuery(options: Omit<Urql.UseQueryArgs<CleanSheetsLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CleanSheetsLeaderboardQuery>({ query: CleanSheetsLeaderboardDocument, ...options });
};
export const GoalsAgainstLeaderboardDocument = gql`
    query GoalsAgainstLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      goalsAgainst(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useGoalsAgainstLeaderboardQuery(options: Omit<Urql.UseQueryArgs<GoalsAgainstLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GoalsAgainstLeaderboardQuery>({ query: GoalsAgainstLeaderboardDocument, ...options });
};
export const GoalsForLeaderboardDocument = gql`
    query GoalsForLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      goalsFor(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useGoalsForLeaderboardQuery(options: Omit<Urql.UseQueryArgs<GoalsForLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GoalsForLeaderboardQuery>({ query: GoalsForLeaderboardDocument, ...options });
};
export const GoalsLeaderboardDocument = gql`
    query GoalsLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      goals(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useGoalsLeaderboardQuery(options: Omit<Urql.UseQueryArgs<GoalsLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GoalsLeaderboardQuery>({ query: GoalsLeaderboardDocument, ...options });
};
export const GoalsPerGameLeaderboardDocument = gql`
    query GoalsPerGameLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      goalsPerGame(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useGoalsPerGameLeaderboardQuery(options: Omit<Urql.UseQueryArgs<GoalsPerGameLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GoalsPerGameLeaderboardQuery>({ query: GoalsPerGameLeaderboardDocument, ...options });
};
export const MatchesDrawnLeaderboardDocument = gql`
    query MatchesDrawnLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      matchesDrawn(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useMatchesDrawnLeaderboardQuery(options: Omit<Urql.UseQueryArgs<MatchesDrawnLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchesDrawnLeaderboardQuery>({ query: MatchesDrawnLeaderboardDocument, ...options });
};
export const MatchesLostLeaderboardDocument = gql`
    query MatchesLostLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      matchesLost(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useMatchesLostLeaderboardQuery(options: Omit<Urql.UseQueryArgs<MatchesLostLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchesLostLeaderboardQuery>({ query: MatchesLostLeaderboardDocument, ...options });
};
export const MatchesPlayedLeaderboardDocument = gql`
    query MatchesPlayedLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      matchesPlayed(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useMatchesPlayedLeaderboardQuery(options: Omit<Urql.UseQueryArgs<MatchesPlayedLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchesPlayedLeaderboardQuery>({ query: MatchesPlayedLeaderboardDocument, ...options });
};
export const MatchesWonLeaderboardDocument = gql`
    query MatchesWonLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      matchesWon(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useMatchesWonLeaderboardQuery(options: Omit<Urql.UseQueryArgs<MatchesWonLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchesWonLeaderboardQuery>({ query: MatchesWonLeaderboardDocument, ...options });
};
export const VotesLeaderboardDocument = gql`
    query VotesLeaderboard($id: ID!, $voteType: String!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      votes(voteType: $voteType, from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useVotesLeaderboardQuery(options: Omit<Urql.UseQueryArgs<VotesLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<VotesLeaderboardQuery>({ query: VotesLeaderboardDocument, ...options });
};
export const WinRateLeaderboardDocument = gql`
    query WinRateLeaderboard($id: ID!, $from: DateTime, $to: DateTime) {
  team(id: $id) {
    id
    leaderboards {
      winRate(from: $from, to: $to) {
        ...LeaderboardItemFragment
      }
    }
  }
}
    ${LeaderboardItemFragmentFragmentDoc}`;

export function useWinRateLeaderboardQuery(options: Omit<Urql.UseQueryArgs<WinRateLeaderboardQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<WinRateLeaderboardQuery>({ query: WinRateLeaderboardDocument, ...options });
};
export const CreateTeamDocument = gql`
    mutation CreateTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      name
      location
      description
    }
  }
}
    `;

export function useCreateTeamMutation() {
  return Urql.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument);
};
export const ApproveTeamRequestDocument = gql`
    mutation ApproveTeamRequest($playerId: ID, $requestId: ID!, $playerName: String) {
  approveTeamRequest(playerId: $playerId, requestId: $requestId, playerName: $playerName) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useApproveTeamRequestMutation() {
  return Urql.useMutation<ApproveTeamRequestMutation, ApproveTeamRequestMutationVariables>(ApproveTeamRequestDocument);
};
export const DeclineTeamRequestDocument = gql`
    mutation DeclineTeamRequest($requestId: ID!) {
  declineTeamRequest(requestId: $requestId) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useDeclineTeamRequestMutation() {
  return Urql.useMutation<DeclineTeamRequestMutation, DeclineTeamRequestMutationVariables>(DeclineTeamRequestDocument);
};
export const CreateClubDocument = gql`
    mutation CreateClub($input: CreateClubInput!) {
  createClub(input: $input) {
    entity {
      id
      name
      teams {
        id
        name
      }
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useCreateClubMutation() {
  return Urql.useMutation<CreateClubMutation, CreateClubMutationVariables>(CreateClubDocument);
};
export const CreateClubRequestDocument = gql`
    mutation CreateClubRequest($input: CreateClubRequestInput!) {
  createClubRequest(input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
    }
  }
}
    `;

export function useCreateClubRequestMutation() {
  return Urql.useMutation<CreateClubRequestMutation, CreateClubRequestMutationVariables>(CreateClubRequestDocument);
};
export const SearchClubsDocument = gql`
    query SearchClubs($query: String!) {
  searchClubs(query: $query) {
    id
    name
    location
  }
}
    `;

export function useSearchClubsQuery(options: Omit<Urql.UseQueryArgs<SearchClubsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SearchClubsQuery>({ query: SearchClubsDocument, ...options });
};
export const AssignPlayerToUserDocument = gql`
    mutation AssignPlayerToUser($input: AssignPlayerToUserInput!) {
  assignPlayerToUser(input: $input) {
    entity {
      id
      name
      user {
        id
        name
      }
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useAssignPlayerToUserMutation() {
  return Urql.useMutation<AssignPlayerToUserMutation, AssignPlayerToUserMutationVariables>(AssignPlayerToUserDocument);
};
export const CoCaptainsDocument = gql`
    query CoCaptains($teamId: ID!) {
  team(id: $teamId) {
    id
    coCaptains {
      id
      name
      profileImage {
        url
        caption
      }
    }
  }
}
    `;

export function useCoCaptainsQuery(options: Omit<Urql.UseQueryArgs<CoCaptainsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<CoCaptainsQuery>({ query: CoCaptainsDocument, ...options });
};
export const CreatePlayerDocument = gql`
    mutation CreatePlayer($name: String!, $teamId: ID!) {
  createPlayer(name: $name, teamId: $teamId) {
    validation {
      key
      reason
    }
    entity {
      id
      name
      team {
        id
        name
        players {
          id
          name
        }
      }
    }
  }
}
    `;

export function useCreatePlayerMutation() {
  return Urql.useMutation<CreatePlayerMutation, CreatePlayerMutationVariables>(CreatePlayerDocument);
};
export const DeletePlayerDocument = gql`
    mutation DeletePlayer($id: ID!) {
  deletePlayer(id: $id) {
    team {
      id
    }
    error
  }
}
    `;

export function useDeletePlayerMutation() {
  return Urql.useMutation<DeletePlayerMutation, DeletePlayerMutationVariables>(DeletePlayerDocument);
};
export const MergePlayersDocument = gql`
    mutation MergePlayers($source: ID!, $target: ID!) {
  mergePlayers(source: $source, target: $target) {
    id
    name
  }
}
    `;

export function useMergePlayersMutation() {
  return Urql.useMutation<MergePlayersMutation, MergePlayersMutationVariables>(MergePlayersDocument);
};
export const SetTeamCoCaptainsDocument = gql`
    mutation SetTeamCoCaptains($input: SetTeamCoCaptainsInput!) {
  setTeamCoCaptains(input: $input) {
    entity {
      id
      coCaptains {
        id
        name
      }
    }
  }
}
    `;

export function useSetTeamCoCaptainsMutation() {
  return Urql.useMutation<SetTeamCoCaptainsMutation, SetTeamCoCaptainsMutationVariables>(SetTeamCoCaptainsDocument);
};
export const TeamPlayersDocument = gql`
    query TeamPlayers($id: ID!) {
  team(id: $id) {
    id
    name
    players {
      ...PlayerFragment
    }
  }
}
    ${PlayerFragmentFragmentDoc}`;

export function useTeamPlayersQuery(options: Omit<Urql.UseQueryArgs<TeamPlayersQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamPlayersQuery>({ query: TeamPlayersDocument, ...options });
};
export const UpdatePlayerDocument = gql`
    mutation UpdatePlayer($id: ID!, $name: String) {
  updatePlayer(id: $id, name: $name) {
    entity {
      id
      name
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useUpdatePlayerMutation() {
  return Urql.useMutation<UpdatePlayerMutation, UpdatePlayerMutationVariables>(UpdatePlayerDocument);
};
export const CreateSeasonDocument = gql`
    mutation CreateSeason($input: CreateSeasonInput!) {
  createSeason(input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      name
      startedAt
      endedAt
    }
  }
}
    `;

export function useCreateSeasonMutation() {
  return Urql.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(CreateSeasonDocument);
};
export const DeleteSeasonDocument = gql`
    mutation DeleteSeason($id: ID!) {
  deleteSeason(id: $id) {
    team {
      id
      seasons {
        id
        name
      }
    }
  }
}
    `;

export function useDeleteSeasonMutation() {
  return Urql.useMutation<DeleteSeasonMutation, DeleteSeasonMutationVariables>(DeleteSeasonDocument);
};
export const GetSeasonDocument = gql`
    query GetSeason($id: ID!) {
  season(id: $id) {
    id
    name
    startedAt
    endedAt
  }
}
    `;

export function useGetSeasonQuery(options: Omit<Urql.UseQueryArgs<GetSeasonQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<GetSeasonQuery>({ query: GetSeasonDocument, ...options });
};
export const TeamSeasonsDocument = gql`
    query TeamSeasons($id: ID!) {
  team(id: $id) {
    id
    seasons {
      id
      name
      startedAt
      endedAt
    }
  }
}
    `;

export function useTeamSeasonsQuery(options: Omit<Urql.UseQueryArgs<TeamSeasonsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TeamSeasonsQuery>({ query: TeamSeasonsDocument, ...options });
};
export const UpdateSeasonDocument = gql`
    mutation UpdateSeason($id: ID!, $input: UpdateSeasonInput!) {
  updateSeason(id: $id, input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      name
      startedAt
      endedAt
    }
  }
}
    `;

export function useUpdateSeasonMutation() {
  return Urql.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument);
};
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($id: ID!, $input: UpdateTeamInput!) {
  updateTeam(id: $id, input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      name
      location
      description
      paymentLink
      leagueLink
    }
  }
}
    `;

export function useUpdateTeamMutation() {
  return Urql.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument);
};
export const UpdateVoteTypeDocument = gql`
    mutation UpdateVoteType($id: ID!, $input: UpdateVoteTypeInput!) {
  updateVoteType(id: $id, input: $input) {
    validation {
      key
      reason
    }
    entity {
      id
      name
      position
      enabled
    }
  }
}
    `;

export function useUpdateVoteTypeMutation() {
  return Urql.useMutation<UpdateVoteTypeMutation, UpdateVoteTypeMutationVariables>(UpdateVoteTypeDocument);
};
export const CreatePushSubscriptionDocument = gql`
    mutation CreatePushSubscription($input: CreatePushSubscriptionInput!) {
  createPushSubscription(input: $input) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useCreatePushSubscriptionMutation() {
  return Urql.useMutation<CreatePushSubscriptionMutation, CreatePushSubscriptionMutationVariables>(CreatePushSubscriptionDocument);
};
export const DeletePushSubscriptionDocument = gql`
    mutation DeletePushSubscription($id: ID!) {
  deletePushSubscription(id: $id) {
    user {
      id
    }
  }
}
    `;

export function useDeletePushSubscriptionMutation() {
  return Urql.useMutation<DeletePushSubscriptionMutation, DeletePushSubscriptionMutationVariables>(DeletePushSubscriptionDocument);
};
export const PushSubscriptionsDocument = gql`
    query PushSubscriptions {
  user {
    id
    pushSubscriptions {
      id
      endpoint
    }
  }
}
    `;

export function usePushSubscriptionsQuery(options: Omit<Urql.UseQueryArgs<PushSubscriptionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PushSubscriptionsQuery>({ query: PushSubscriptionsDocument, ...options });
};
export const SendTestMessageDocument = gql`
    mutation SendTestMessage($input: TestPushMessageInput!) {
  sendTestPushMessage(input: $input)
}
    `;

export function useSendTestMessageMutation() {
  return Urql.useMutation<SendTestMessageMutation, SendTestMessageMutationVariables>(SendTestMessageDocument);
};
export const DisableTotpDocument = gql`
    mutation DisableTotp {
  disableTotp {
    entity {
      id
    }
  }
}
    `;

export function useDisableTotpMutation() {
  return Urql.useMutation<DisableTotpMutation, DisableTotpMutationVariables>(DisableTotpDocument);
};
export const EnableTotpDocument = gql`
    mutation EnableTotp($input: EnableTotpInput!) {
  enableTotp(input: $input) {
    entity {
      id
    }
  }
}
    `;

export function useEnableTotpMutation() {
  return Urql.useMutation<EnableTotpMutation, EnableTotpMutationVariables>(EnableTotpDocument);
};
export const NotificationPreferencesDocument = gql`
    query NotificationPreferences {
  notifications {
    id
    name
    slug
    description
    enabled
  }
}
    `;

export function useNotificationPreferencesQuery(options: Omit<Urql.UseQueryArgs<NotificationPreferencesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<NotificationPreferencesQuery>({ query: NotificationPreferencesDocument, ...options });
};
export const SetNotificationPreferenceDocument = gql`
    mutation SetNotificationPreference($notificationId: ID!, $enabled: Boolean!) {
  setNotificationPreference(notificationId: $notificationId, enabled: $enabled) {
    notifications {
      id
      name
      slug
      enabled
    }
  }
}
    `;

export function useSetNotificationPreferenceMutation() {
  return Urql.useMutation<SetNotificationPreferenceMutation, SetNotificationPreferenceMutationVariables>(SetNotificationPreferenceDocument);
};
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    entity {
      id
      name
      email
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useUpdateUserMutation() {
  return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument);
};
export const CloseMatchVotingDocument = gql`
    mutation CloseMatchVoting($id: UUID!) {
  closeMatchVoting(id: $id) {
    entity {
      id
    }
    validation {
      key
      reason
    }
  }
}
    `;

export function useCloseMatchVotingMutation() {
  return Urql.useMutation<CloseMatchVotingMutation, CloseMatchVotingMutationVariables>(CloseMatchVotingDocument);
};
export const CreateVoteDocument = gql`
    mutation CreateVote($input: CreateVoteInput!) {
  createVote(input: $input) {
    validation {
      key
      reason
    }
    entity {
      match {
        id
      }
    }
  }
}
    `;

export function useCreateVoteMutation() {
  return Urql.useMutation<CreateVoteMutation, CreateVoteMutationVariables>(CreateVoteDocument);
};
export const MatchVoteCountsDocument = gql`
    query MatchVoteCounts($matchId: UUID!, $voteTypeId: ID) {
  match(id: $matchId) {
    id
    voteResult {
      counts(voteTypeId: $voteTypeId) {
        ...PlayerVoteCountFragment
      }
    }
  }
}
    ${PlayerVoteCountFragmentFragmentDoc}`;

export function useMatchVoteCountsQuery(options: Omit<Urql.UseQueryArgs<MatchVoteCountsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchVoteCountsQuery>({ query: MatchVoteCountsDocument, ...options });
};
export const MatchVotesDocument = gql`
    query MatchVotes($matchId: UUID!) {
  match(id: $matchId) {
    id
    voteResult {
      votes {
        ...VoteFragment
      }
    }
  }
}
    ${VoteFragmentFragmentDoc}`;

export function useMatchVotesQuery(options: Omit<Urql.UseQueryArgs<MatchVotesQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MatchVotesQuery>({ query: MatchVotesDocument, ...options });
};
export const VotesCastDocument = gql`
    query VotesCast($matchId: UUID!) {
  match(id: $matchId) {
    id
    voteTypesCast
  }
}
    `;

export function useVotesCastQuery(options: Omit<Urql.UseQueryArgs<VotesCastQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<VotesCastQuery>({ query: VotesCastDocument, ...options });
};
export const ConfirmEmailAddressDocument = gql`
    mutation ConfirmEmailAddress($token: String!) {
  confirmEmailAddress(token: $token) {
    validation {
      key
      reason
    }
  }
}
    `;

export function useConfirmEmailAddressMutation() {
  return Urql.useMutation<ConfirmEmailAddressMutation, ConfirmEmailAddressMutationVariables>(ConfirmEmailAddressDocument);
};
export const RefreshSessionDocument = gql`
    mutation RefreshSession {
  refreshSession {
    entity {
      user {
        name
      }
    }
  }
}
    `;

export function useRefreshSessionMutation() {
  return Urql.useMutation<RefreshSessionMutation, RefreshSessionMutationVariables>(RefreshSessionDocument);
};