import * as React from 'react';
import { TextButton } from '../components/ClickableText';

import { AuthLayout } from '../layouts/AuthLayout';
import { useAnalytics } from '../contexts/AnalyticsContext';

const FailWhale = () => (
  <span className="text-6xl block transform rotate-180">🐳</span>
);

export const ServerDownPage: React.FC = ({}) => {
  const reloadPage = window.location.reload.bind(window.location);
  const analytics = useAnalytics();
  // explicitly check for false in case the browser does not support this
  const isOffline = window.navigator.onLine === false;

  React.useEffect(() => {
    analytics.track('NetworkError Encountered', { isOffline });
  }, [isOffline, analytics]);

  if (isOffline) {
    return (
      <AuthLayout>
        <div className="text-center space-y-10">
          <h1 className="text-2xl">It looks like you're offline</h1>
          <FailWhale />
          <p className="text-center">
            Check your internet connection and try{' '}
            <TextButton onClick={reloadPage}>refreshing the page</TextButton>.
          </p>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout>
      <div className="text-center space-y-10">
        <h1 className="text-2xl">We're having a few issues</h1>
        <FailWhale />
        <p className="text-center">
          Something is broken on our end. We're working on it though! Try
          waiting a minute or two before{' '}
          <TextButton onClick={reloadPage}>refreshing the page</TextButton>.
        </p>
      </div>
    </AuthLayout>
  );
};
