import * as Sentry from '@sentry/react';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import {
  createClient,
  dedupExchange,
  cacheExchange,
  fetchExchange,
} from 'urql';

import posthog from 'posthog-js';
import { App } from './App';
import { config } from './config';
import { ErrorBoundary } from './ErrorBoundary';
import './styles/main.css';

import { AuthProvider } from './contexts/AuthContext';
import { ClientProvider } from './contexts/ClientContext';
import { GeocoderProvider } from './contexts/GeocoderContext';
import { mapboxGeocoder } from './services/geocoding';

import { errorExchange } from './graphql/exchanges/errorExchange';
import { SnackbarAlertProvider } from './contexts/AlertContext';
import { getPWADisplayMode } from './utils/getPwaDisplayMode';
import { getAnalyticsDriver } from './services/analytics';
import { ServicesProvider } from './services/services';

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    release: config.version,
  });

  Sentry.configureScope((scope) => {
    scope.setTag('displayMode', getPWADisplayMode());
  });
}

const reportError = config.sentryDsn ? Sentry.captureException : console.error;
const identifyUser = config.sentryDsn
  ? Sentry.setUser
  : (user: any) => console.info('User identified:', user);

if (config.analyticsDriver === 'posthog') {
  posthog.init(config.posthogToken, {
    api_host: config.posthogApiHost,
    person_profiles: 'identified_only',
  });
}

const makeClient = () =>
  createClient({
    url: '/graphql',
    exchanges: [
      dedupExchange,
      cacheExchange,
      errorExchange(reportError),
      fetchExchange,
    ],
  });

const analyticsFactory = () => getAnalyticsDriver(config.analyticsDriver);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <ErrorBoundary
      analytics={analyticsFactory()}
      reloadPage={window.location.reload.bind(window.location)}
      logError={reportError}
    >
      <ServicesProvider store={store} analytics={analyticsFactory}>
        <SnackbarAlertProvider>
          <GeocoderProvider geocoder={mapboxGeocoder}>
            <ClientProvider makeClient={makeClient}>
              <AuthProvider>
                <App identifyUser={identifyUser} />
              </AuthProvider>
            </ClientProvider>
          </GeocoderProvider>
        </SnackbarAlertProvider>
      </ServicesProvider>
    </ErrorBoundary>
  </BrowserRouter>,
);

window.addEventListener('appinstalled', () => {
  getAnalyticsDriver(config.analyticsDriver).track('App Installed');
});

if ('serviceWorker' in navigator) {
  (async () =>
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => console.log('service worker registered')))().catch((error) =>
    console.error('Failed to register service worker', error),
  );
}
