import makeMapboxClient from '@mapbox/mapbox-sdk';
import makeGeocoderService from '@mapbox/mapbox-sdk/services/geocoding';
import { config } from '../config';
import { Geocoder } from '../contexts/GeocoderContext';

const mbxClient = makeMapboxClient({ accessToken: config.mapboxToken });
const mbxGeocoder = makeGeocoderService(mbxClient);

/**
 * An implementation of a geocoder that uses Mapbox
 */
export const mapboxGeocoder: Geocoder = {
  forwardGeocode: (query) => {
    return mbxGeocoder
      .forwardGeocode({
        query,
        mode: 'mapbox.places',
        types: ['poi', 'address', 'postcode', 'locality'],
      })
      .send()
      .then((res) => {
        // mapbox returns GeoJson so we'll just extract out the parts we care about
        return res.body.features.map((feature) => {
          // geojson uses long, lat order, whilst libraries tend to use lat, lng
          const [lng, lat] = feature.center;

          return {
            coords: [lat, lng],
            address: feature.place_name,
          };
        });
      });
  },
};
