import * as React from 'react';

export const DialogButton: React.FC<React.ComponentProps<'button'>> = ({
  className = '',
  ...props
}) => {
  return (
    <button
      {...props}
      className={`block w-full focus:outline-none text-center px-4 py-3 ${className}`}
      type="button"
    ></button>
  );
};
