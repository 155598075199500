import * as React from 'react';

export const DialogContentContainer: React.FC<React.ComponentProps<'div'>> = ({
  className = '',
  children,
}) => {
  return (
    <div
      className={`w-full block my-8 transition-all transform bg-white shadow-xl rounded-lg ${className}`}
    >
      {children}
    </div>
  );
};
