import { constUndefined } from 'fp-ts/lib/function';
import * as React from 'react';

import { Client, Provider as UrqlProvider } from 'urql';

interface ClientState {
  resetClient: () => void;
}

const ClientContext = React.createContext<ClientState>({
  resetClient: constUndefined,
});

interface Props {
  makeClient: () => Client;
  children: React.ReactNode;
}

/**
 * This context allows us to "refresh" our graphql state
 * by resetting the entire client when we need to. E.g.
 * when a user logs in/out.
 *
 */
function ClientProvider({ makeClient, children }: Props) {
  const [client, setClient] = React.useState(makeClient());

  return (
    <ClientContext.Provider
      value={{
        resetClient: () => setClient(makeClient()),
      }}
    >
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </ClientContext.Provider>
  );
}

const useClient = () => React.useContext(ClientContext);

export { ClientProvider, useClient };
