import * as React from 'react';

export type AlertType = 'success' | 'warning' | 'danger';

interface Props {
  kind: AlertType;
}

const colorClasses = {
  success: 'bg-green-500 text-green-900',
  warning: 'bg-amber-300 text-amber-900',
  danger: 'bg-red-500 text-red-100',
};

export const Snackbar: React.FC<Props> = ({ children, kind }) => {
  const colorClass = colorClasses[kind] || '';

  return (
    <div
      className={`mt-2 p-4 rounded-lg overflow-hidden shadow-md flex items-center ${colorClass}`}
    >
      {children}
    </div>
  );
};
