import * as React from 'react';
import { Store } from '../services/store';

const StoreContext = React.createContext<Store | null>(null);

interface Props {
  store: Store;
}

export const StoreProvider: React.FC<Props> = ({ store, children }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => {
  const value = React.useContext(StoreContext);

  if (!value) {
    throw new Error('useStore must be used inside of a StoreProvider');
  }

  return value;
};
