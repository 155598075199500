import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { InternalLink } from './InternalLink';

const clickableTextClass =
  'text-primary-600 hover:text-primary-400 transition duration-300 font-medium';

/**
 * A react-router link styled for use in inline text
 */
export const TextLink: React.FC<LinkProps> = ({ className = '', ...props }) => {
  return (
    <InternalLink className={`${clickableTextClass} ${className}`} {...props} />
  );
};

/**
 * A custom external link styled for use in inline text
 */
export const ExternalTextLink: React.FC<React.ComponentProps<'a'>> = ({
  className = '',
  ...props
}) => {
  return <a className={`${clickableTextClass} ${className}`} {...props} />;
};

/**
 * A button styled for use in inline text
 */
export const TextButton: React.FC<React.ComponentProps<'button'>> = ({
  className = '',
  ...props
}) => {
  return <button className={`${clickableTextClass} ${className}`} {...props} />;
};
