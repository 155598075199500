import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { InternalLink } from './InternalLink';

type PillButtonKind = 'secondary' | 'primary' | 'plain';

const clickablePillClass =
  'inline-flex rounded-md border px-5 py-2 transition duration-300';

const colorClass = (kind: PillButtonKind) => {
  if (kind === 'primary') {
    return 'text-white bg-primary-500 disabled:bg-primary-300 border-primary-500 hover:bg-primary-400 hover:border-primary-400';
  }

  if (kind === 'secondary') {
    return 'text-primary-500 bg-white hover:bg-grey-100 border-primary-500';
  }

  if (kind === 'plain') {
    return 'text-primary-500 bg-white hover:bg-grey-100 border-white hover:border-grey-100';
  }
};

interface Props {
  kind?: PillButtonKind;
}

export const PillButton: React.FC<React.ComponentProps<'button'> & Props> = ({
  className = '',
  kind = 'secondary',
  ...props
}) => {
  return (
    <button
      className={`${clickablePillClass} ${colorClass(kind)} ${className}`}
      {...props}
    />
  );
};

export const PillLink: React.FC<LinkProps & Props> = ({
  className = '',
  kind = 'secondary',
  ...props
}) => {
  return (
    <InternalLink
      className={`${clickablePillClass} ${colorClass(kind)} ${className}`}
      {...props}
    />
  );
};
