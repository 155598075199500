import * as React from 'react';

import { Transition } from '@headlessui/react';
import { AlertType, Snackbar } from '../components/Snackbar';
import { wait } from '../utils/wait';

export const AlertContext = React.createContext<Alerter>(async () => undefined);

const getMessageId = () => {
  return Math.floor(Math.random() * 1e8).toString();
};
type Alerter = (message: string, type?: AlertType) => Promise<void>;

interface Alert {
  id: string;
  message: string;
  visible: boolean;
  kind: AlertType;
}

type State = Alert;

type AlertAction =
  | { type: 'DisplayAlert'; id: string; message: string; kind: AlertType }
  | { type: 'RemoveAlert'; id: string };

const initialState: State = {
  id: 'init',
  message: '',
  visible: false,
  kind: 'success',
};

const reducer = (state: State, action: AlertAction): State => {
  switch (action.type) {
    case 'DisplayAlert':
      return {
        id: action.id,
        message: action.message,
        visible: true,
        kind: action.kind,
      };

    case 'RemoveAlert':
      return action.id === state.id ? { ...state, visible: false } : state;

    default:
      throw new Error(`Alert action not supported: ${action}`);
  }
};

const ToastVisibleDuration = 3400;

export const SnackbarAlertProvider: React.FC = ({ children }) => {
  const [alert, dispatch] = React.useReducer(reducer, initialState);

  const doAlert = async (message: string, kind: AlertType = 'success') => {
    if (alert.visible) {
      dispatch({ type: 'RemoveAlert', id: alert.id });
      await wait(200);
    }
    const id = getMessageId();

    dispatch({ type: 'DisplayAlert', id, message, kind });
    await wait(ToastVisibleDuration);
    dispatch({ type: 'RemoveAlert', id });
  };

  const dismissAlert = () => dispatch({ type: 'RemoveAlert', id: alert.id });

  return (
    <AlertContext.Provider value={doAlert}>
      {children}

      <Transition
        show={alert.visible}
        className="fixed right-0 bottom-0 w-full sm:w-1/2 lg:w-1/3 mw-full z-50"
        enter="transition duration-200"
        enterFrom="transform opacity-50 translate-y-full"
        enterTo="transform opacity-100 translate-y-0"
        leave="transition ease-in duration-200 transform origin-bottom-right"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-75"
      >
        <div onClick={dismissAlert} className="p-4 flex flex-col-reverse">
          <Snackbar kind={alert.kind}>{alert.message}</Snackbar>
        </div>
      </Transition>
    </AlertContext.Provider>
  );
};

export const useAlert = () => React.useContext(AlertContext);
