import * as React from 'react';

import { Lazy } from 'fp-ts/lib/function';
import { StoreProvider } from '../contexts/StoreContext';
import { AnalyticsProvider } from '../contexts/AnalyticsContext';
import { CookieConsentProvider } from '../contexts/CookieConsentContext';
import { Analytics, nullAnalytics } from './analytics';
import { Store, createMemoryStorage } from './store';

export interface Services {
  store: Store;
  analytics: Lazy<Analytics>;
}

export const baseServices: Services = {
  store: createMemoryStorage(),
  analytics: () => nullAnalytics,
};

export const ServicesProvider: React.FC<Services> = ({
  store,
  children,
  analytics,
}) => {
  return (
    <CookieConsentProvider>
      <AnalyticsProvider analytics={analytics}>
        <StoreProvider store={store}>{children}</StoreProvider>
      </AnalyticsProvider>
    </CookieConsentProvider>
  );
};
