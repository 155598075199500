import { constNull } from 'fp-ts/lib/function';
import posthog from 'posthog-js';

type AllowedEvent =
  | 'Auth Check Failed'
  | 'App Installed'
  | 'Data Submit Failed'
  | 'Data Submit Succeeded'
  | 'Team Switched'
  | 'Clickable Clicked'
  | 'Form Submitted'
  | 'Link Copied'
  | 'Link Shared'
  | 'PageReload Forced'
  | 'UnfinishedFeature Triggered'
  | 'PaymentLink Shared'
  | 'View Viewed'
  | 'NetworkError Encountered';

export interface Analytics {
  identify(userId: string | number, traits?: Record<string, any>): void;
  track(event: AllowedEvent, properties?: Record<string, any>): void;
  page(name: string, properties?: Record<string, any>): void;
  unidentify(): void;
}

export const nullAnalytics: Analytics = {
  identify: constNull,
  track: constNull,
  page: constNull,
  unidentify: constNull,
};

export const consoleAnalytics: Analytics = {
  identify: (user: string, traits?: Record<string, any>) => {
    if (typeof user === 'object') {
      console.groupCollapsed(`[Analytics Identify]`);
      console.table(user);
      if (traits) {
        console.table(traits);
      }
      console.groupEnd();
    } else {
      console.groupCollapsed(`[Analytics Identify] ${user}`);
      console.table(traits);
      console.groupEnd();
    }
  },
  track: (event, properties) => {
    console.groupCollapsed(`[Analytics Track] ${event}`);
    console.table(properties);
    console.groupEnd();
  },
  page: (name) => {
    console.log(`[Analytics Page] ${name}`);
  },
  unidentify: () => console.log('[Analytics Unidentify]'),
};

const getSegmentAnalytics = (): Analytics => {
  if (!window.analytics) {
    console.warn('Segment analytics not loaded');
    return nullAnalytics;
  }
  return {
    identify: window.analytics.identify,
    track: window.analytics.track,
    page: window.analytics.page,
    unidentify: () => window.analytics.identify(''),
  };
};

const posthogAnalytics: Analytics = {
  identify: (id, props) => posthog.identify(`${id}`, props),
  track: (event, props) => posthog.capture(event, props),
  page: () => posthog.capture('$pageview'),
  unidentify: () => posthog.reset(),
};

export const getAnalyticsDriver = (driver: string): Analytics => {
  if (driver === 'console') {
    return consoleAnalytics;
  }

  if (driver === 'segment' && window.analytics) {
    return getSegmentAnalytics();
  }
  if (driver === 'posthog') {
    return posthogAnalytics;
  }
  return nullAnalytics;
};
