import * as React from 'react';
import { Dialog } from '@headlessui/react';
import { DialogButton } from './DialogButton';

interface Props {
  title: string;
  onOk: () => void;
  onCancel: () => void;
  okButtonText?: string;
  cancelButtonText?: string;
  destructive?: boolean;
}

export const ConfirmationModalContent: React.FC<Props> = ({
  title,
  children,
  onOk,
  onCancel,
  okButtonText = 'OK',
  cancelButtonText = 'Cancel',
  destructive = false,
}) => {
  return (
    <>
      <div className="mb-6 text-center px-4 pt-4 space-y-4">
        <Dialog.Title as="h3" className="text-lg font-medium leading-6 ">
          {title}
        </Dialog.Title>
        <div className="text-sm space-y-2 text-grey-700">{children}</div>
      </div>
      <ul className="divide-y divide-grey-200">
        <li>
          <DialogButton
            className={
              destructive
                ? 'text-red-700 hover:text-red-500 font-semibold'
                : 'text-primary-600 hover:text-primary-400 font-semibold'
            }
            onClick={onOk}
          >
            {okButtonText}
          </DialogButton>
        </li>
        <li>
          <DialogButton onClick={onCancel}>{cancelButtonText}</DialogButton>
        </li>
      </ul>
    </>
  );
};
