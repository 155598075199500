import * as React from 'react';

import { AuthLayout } from '../layouts/AuthLayout';
import { TextButton, TextLink } from '../components/ClickableText';

interface Props {
  onNavigate?: () => void;
}

const FailWhale = () => (
  <span className="text-6xl block transform rotate-180">🐳</span>
);

export const Failure = ({ onNavigate = () => null }: Props) => {
  return (
    <AuthLayout>
      <div className="text-center space-y-10">
        <FailWhale />
        <p className="text-center">
          Something went badly wrong.{' '}
          <TextButton onClick={() => window.location.reload()}>
            Try reloading the app
          </TextButton>{' '}
          or{' '}
          <TextLink to="/help" onClick={onNavigate}>
            send us a message
          </TextLink>
          .
        </p>
      </div>
    </AuthLayout>
  );
};
