import * as React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../components/Container';

import Logo from '../matchhaus-sticker.svg';

export const AuthLayout: React.FC = ({ children }) => {
  return (
    <div className="bg-theme2 min-h-screen text-white pt-safe">
      <Container>
        <div>
          <div className="pt-14 max-w-sm px-8 mx-auto mb-8">
            <Link to="/" className="inline-block">
              <img src={Logo} alt="Matchhaus logo" />
            </Link>
          </div>
          {children}
        </div>
      </Container>
    </div>
  );
};
