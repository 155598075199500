import * as React from 'react';

import * as O from 'fp-ts/Option';

import { Lazy } from 'fp-ts/lib/function';
import {
  Analytics,
  getAnalyticsDriver,
  nullAnalytics,
} from '../services/analytics';

import { config } from '../config';
import { useCookieConsent } from './CookieConsentContext';

const AnalyticsContext = React.createContext<Lazy<Analytics>>(
  () => nullAnalytics,
);

interface Props {
  analytics: Lazy<Analytics>;
}

export const AnalyticsProvider: React.FC<Props> = ({ children }) => {
  const analytics = () => getAnalyticsDriver(config.analyticsDriver);

  const consented = useCookieConsent();

  React.useEffect(() => {
    if (O.isSome(consented) && consented.value) {
      if (config.analyticsDriver === 'segment' && config.segmentKey) {
        window.analytics.load(config.segmentKey);
      }
    }
  }, [consented]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const value = React.useContext(AnalyticsContext);

  if (!value) {
    throw new Error('useAnalytics must be used inside of a AnalyticsProvider');
  }

  return value();
};
