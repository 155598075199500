import React from 'react';
import * as O from 'fp-ts/Option';

import { pipe, constUndefined } from 'fp-ts/lib/function';
import {
  useUserQuery,
  FullUserFragment,
  useLogoutMutation,
} from '../generated/graphql';
import { Loader } from '../components/LoadingContainer';
import { ServerDownPage } from '../pages/ServerDown';
import { useClient } from './ClientContext';

export type User = FullUserFragment;

export interface AuthState {
  user: User | null;
  logout: () => void;
  refetchUser: () => void;
}

export const AuthContext = React.createContext<AuthState>({
  user: null,
  logout: constUndefined,
  refetchUser: constUndefined,
});

interface Props {
  children: React.ReactNode;
}

function AuthProvider({ children }: Props) {
  // Try and get the user from the server
  const [userQueryResponse, executeUserQuery] = useUserQuery();
  const { resetClient } = useClient();
  const refetchUser = React.useCallback(
    () => executeUserQuery({ requestPolicy: 'cache-and-network' }),
    [executeUserQuery],
  );

  const [, dispatchLogout] = useLogoutMutation();

  // Normally provider components render the context provider with a value.
  // But we postpone rendering any of the children until after we've determined
  // whether or not we have a user and while we do, then we render a spinner
  // while we go retrieve that user's information.
  if (userQueryResponse.fetching) {
    return <Loader />;
  }

  // Check for a network error here. This should catch the server being down or
  // the user's device being offline.
  if (userQueryResponse.error?.networkError) {
    console.warn(userQueryResponse.error);
    return <ServerDownPage />;
  }

  // Upon logout we should reset the GraphQL client which will flush any cached responses
  const logout = () => dispatchLogout().then(resetClient);

  const user = pipe(userQueryResponse.data?.user, O.fromNullable, O.toNullable);

  return (
    <AuthContext.Provider value={{ user, logout, refetchUser }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
