import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useLocation } from 'react-router';
import { paths } from '../paths';

/**
 * A react router Link, but one that adds the current location to its state
 * so a back button can know where to send the user.
 *
 * @param props
 */
export const InternalLink: React.FC<NavLinkProps> = ({ to, ...linkProps }) => {
  const { pathname } = useLocation();
  const prevPathState = { prevPath: pathname };

  const t =
    typeof to === 'string'
      ? { pathname: to, state: prevPathState }
      : {
          ...to,
          state: { ...(to.state || {}), ...prevPathState },
        };

  return <NavLink {...linkProps} to={t} />;
};

/**
 * Get the previous pathname by looking in the route state for a `prevPath` property.
 *
 * @param fallback the path to use if no `prevPath` is set
 */
export const usePreviousPathname = (fallback: string = paths.home()) => {
  const { state } = useLocation<{ prevPath?: string } | undefined>();

  return state?.prevPath || fallback;
};
