import * as React from 'react';

export interface GeocodeResult {
  coords: [number, number];
  address: string;
}
export interface Geocoder {
  forwardGeocode: (query: string) => Promise<GeocodeResult[]>;
}

const fakeGeocoder: Geocoder = {
  forwardGeocode: () => Promise.resolve([]),
};

const GeocodeContext = React.createContext<Geocoder>(fakeGeocoder);

interface Props {
  children: React.ReactNode;
  geocoder: Geocoder;
}

export const GeocoderProvider = ({ children, geocoder }: Props) => {
  return (
    <GeocodeContext.Provider value={geocoder}>
      {children}
    </GeocodeContext.Provider>
  );
};

export const useGeocoder = () => {
  return React.useContext(GeocodeContext);
};
